<template>
	<div>
		<span
			id="hidden"
			ref="hidden"
			style="border:solid 1px green; visibility:hidden;"
			>{{ selectorText }}</span
		>
		<v-menu
			ref="menu"
			transition="scale-transition"
			offset-y
			min-width="300px"
			dense
			:close-on-content-click="false"
			v-model="menuIsOpen"
			v-if="compCurrentHub"
		>
			<template v-slot:activator="{ on, attrs }">
				<div
					:style="{ width: `${hubTextWidth + 50}px`, cursor: 'pointer' }"
					v-on="on"
				>
					<v-text-field v-model="selectorText" readonly v-bind="attrs">
						<template v-slot:append-outer>
							<v-icon>mdi-menu-down</v-icon>
						</template>
					</v-text-field>
				</div>
			</template>
			<v-card
				v-if="compCurrentHub && compCurrentProj && projects"
				id="selector-card"
			>
				<v-card-text id="selector-card-text">
					<!-- HUB SELECTOR -->
					<v-scroll-x-reverse-transition hide-on-leave>
						<div v-show="showAccountSelection">
							<v-btn
								text
								color="primary"
								@click="showAccountSelection = false"
								id="return-btn"
							>
								<v-icon>mdi-keyboard-backspace</v-icon>
								Back to projects
							</v-btn>

							<v-divider></v-divider>

							<!-- HUB FILTER -->
							<v-text-field
								v-model="hubFilter"
								append-icon="mdi-magnify"
								label="Filter accounts"
								hide-details
								clearable
								outlined
								dense
								class="mt-4"
								color="darken-2"
							></v-text-field>

							<!-- HUB LIST -->
							<v-list dense subheader>
								<v-subheader>Accounts</v-subheader>
								<v-list-item-group v-model="compCurrentHub" color="primary">
									<v-list-item
										v-for="hub in filteredHubs"
										:key="hub.id"
										@click="
											menuIsOpen = !menuIsOpen;
											showAccountSelection = false;
										"
										:value="hub"
									>
										<v-list-item-content
											v-text="hub.text"
										></v-list-item-content>
									</v-list-item>
								</v-list-item-group>
							</v-list>
						</div>
					</v-scroll-x-reverse-transition>

					<!-- PROJECT SELECTOR -->
					<v-scroll-x-transition hide-on-leave>
						<div v-show="!showAccountSelection">
							<!-- CURRENT HUB -->
							<v-card :elevation="0">
								<v-card-title>{{ compCurrentHub.text }}</v-card-title>
								<v-card-actions>
									<span>{{ projects.length }} Projects</span>
									<v-spacer></v-spacer>
									<v-btn
										text
										color="primary"
										@click="showAccountSelection = true"
										>Change Account</v-btn
									>
								</v-card-actions>
							</v-card>

							<v-divider></v-divider>

							<!-- PROJECT FILTER -->
							<v-text-field
								v-model="projectFilter"
								append-icon="mdi-magnify"
								label="Filter projects"
								hide-details
								clearable
								outlined
								dense
								class="mt-4"
								color="darken-2"
							></v-text-field>

							<!-- PROJECT LIST -->
							<v-list dense subheader v-if="projects.length > 0">
								<v-subheader>Projects</v-subheader>
								<v-list-item-group v-model="compCurrentProj" color="primary">
									<v-list-item
										v-for="proj in filteredProjects"
										:key="proj.id"
										@click="
											menuIsOpen = !menuIsOpen;
											showAccountSelection = false;
										"
										:value="proj"
									>
										<v-list-item-content>
											<v-list-item-title>{{ proj.text }}</v-list-item-title>
											<v-list-item-subtitle>{{
												compCurrentHub.text
											}}</v-list-item-subtitle>
										</v-list-item-content>
									</v-list-item>
								</v-list-item-group>
							</v-list>
						</div>
					</v-scroll-x-transition>
				</v-card-text>
			</v-card>
		</v-menu>
	</div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
	data() {
		return {
			menuIsOpen: null,
			showAccountSelection: false,
			hubFilter: null,
			projectFilter: null,
			hubTextWidth: 0,
		};
	},
	computed: {
		...mapGetters(["hubList", "currentHub", "projects", "selectedProject"]),
		compCurrentHub: {
			get() {
				return this.currentHub;
			},
			async set(val) {
				const th = this;
				if (val) {
					await th.setCurrentHub(val);

					// Load projects
					await th.loadProjects();

					// Set current project
					let sp = th.$cookies.get("user_selectedProject");
					let fromcookie;
					if (sp) {
						fromcookie = th.projects.find((h) => {
							return h.id == sp.projectId;
						});
					}
					await th.setSelectedProject(
						fromcookie != null ? fromcookie : th.projects[0]
					);

					// Set file list
					th.setVcadFileList();

					// Redirect user to doc manager
					let currPath = th.$router.currentRoute.path;
					if (currPath == "/not-active") {
						th.$router.push({ path: "/b360-doc-manager" });
					}
				}
			},
		},
		compCurrentProj: {
			get() {
				return this.selectedProject;
			},
			set(val) {
				if (val) {
					this.setSelectedProject(val);
					const projectItemId = val.itemId;
					this.$router.push({
						name: "doc-manager",
						params: {
							startTargetProjectItemId: projectItemId,
							startTargetFolderItemId: undefined,
						},
					});
				}
			},
		},
		filteredHubs() {
			if (this.hubFilter) {
				return this.hubList.filter((hub) =>
					hub.text.toLowerCase().includes(this.hubFilter.toLowerCase())
				);
			} else {
				return this.hubList;
			}
		},
		filteredProjects() {
			if (this.projectFilter) {
				return this.projects.filter((proj) =>
					proj.text.toLowerCase().includes(this.projectFilter.toLowerCase())
				);
			} else {
				return this.projects;
			}
		},
		selectorText() {
			let hubStr = this.compCurrentHub ? this.compCurrentHub.text : "";
			let projStr = this.compCurrentProj ? this.compCurrentProj.text : "";
			return `${hubStr} > ${projStr}`;
		},
	},
	watch: {
		selectorText() {
			//Update text field width
			let th = this;
			this.$nextTick().then(() => {
				th.hubTextWidth = document.getElementById("hidden")
					? document.getElementById("hidden").offsetWidth
					: 0;
			});
		},
	},
	methods: {
		...mapActions(["setCurrentHub", "setSelectedProject", "setVcadFileList", "loadProjects"]),
	},
};
</script>

<style lang="css" scoped>
#selector-card {
	width: 25em;
}

#selector-card-text {
	max-height: 30em;
	overflow: auto;
}

#return-btn {
	margin-bottom: 8px;
}

#selector-field {
	max-width: 20em;
	margin-top: 2.1em;
	cursor: pointer;
}
</style>
