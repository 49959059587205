<template>
  <div>
    <v-progress-linear
      indeterminate
      height="2"
      style="width: 100%"
      v-if="projectIsLoading"
    ></v-progress-linear>
    <v-treeview
      :items="folders"
      item-key="itemId"
      item-text="text"
      item-children="children"
      :activatable="!(disableClick || folderIsLoading)"
      :active.sync="activeNodeIds"
      :open="openNodeIds"
      @update:open="updateOpenNodes"
      color="secondary"
      v-if="folders"
      style="cursor: pointer"
    >
      <!-- FOLDER LABEL -->
      <template v-slot:label="{ item }">
        <span>{{ item.text }}</span>
        <v-progress-linear
          indeterminate
          height="2"
          v-if="folderIsLoading && activeNodeIds.includes(item.itemId)"
        ></v-progress-linear>
      </template>
      <template v-slot:prepend="{ open }">
        <v-icon >
          {{ open ? "mdi-folder-open" : "mdi-folder" }}
        </v-icon>
        
      </template>
    </v-treeview>

    <!-- ERROR MANAGEMENT -->
    <vcad-snackbar
      :value="showErrorMsg"
      :text="errorMsg"
      :customTimeout="2000"
      customColor="warning"
      @closeSnack="showErrorMsg = false"
    ></vcad-snackbar>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import FolderService, { VcadFolder } from "../services/folderService";
import VcadSnackbar from "../components/VcadSnackbar.vue";

export default {
  components: {
    "vcad-snackbar": VcadSnackbar,
  },
  props: {
    // /**
    //  * Currently selected project. Used for the initial tree load.
    //  */
    // selectedProject: {
    // 	type: Object,
    // 	required: true,
    // },
    targetProjectId: {
      type: String,
      required: false,
      default: null,
    },
    /**
     * Target folder to open. Used for the initial tree load
     */
    targetFolderId: {
      type: String,
      required: false,
      default: null,
    },
    /**
     * Disables tree item selection. Used to prevent overloading and conflicts of tree.
     */
    disableClick: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data: () => ({
    folders: [],
    openNodeIds: [],
    activeNodeIds: [],
    showErrorMsg: false,
    errorMsg: "",

    // optional
    projectIsLoading: false,
    folderIsLoading: false,
  }),
  computed: {
    ...mapGetters({
      selectedProjectFromStore: "selectedProject",
      projects: "projects",
    }),
    folderDict() {
      let treeMap = {};
      const mapChildren = (children) => {
        children.forEach((folder) => {
          const children = [...folder.children];
          // delete folder.children;
          treeMap[folder.itemId] = folder;
          if (folder.children) {
            mapChildren(children);
          }
        });
      };
      const foldersCopy = [...this.folders];
      mapChildren(foldersCopy);
      return treeMap;
    },
    targetProjectAndFolder() {
      return `${this.targetProjectId}/${this.targetFolderId}`;
    },
  },
  watch: {
    async selectedProjectFromStore(newSelectedProject){
      if(newSelectedProject){
        this.projectIsLoading = true;

        this.folders = await this.getProjectRootFolders(newSelectedProject);
        this.openNodeIds = [];
        this.activeNodeIds = [];

        this.projectIsLoading = false;
      }
    },
    targetProjectAndFolder() {
      if (this.targetFolderId == this.activeNodeIds[0]) {
        return;
      }
      this.loadFolderTree();
    },
    activeNodeIds: {
      async handler(newVal, oldVal) {
        let folder = null;
        if (newVal.length > 0) {
          const folderId = newVal[0];
          folder = this.folderDict[folderId];

          // Load children if necessary
          const hasChildren = folder.children && folder.children.length > 0;
          if (!hasChildren) {
            this.folderIsLoading = true;
            await this.loadSubFolder(folder);
            this.folderIsLoading = false;
          }
          // Toggle node open
          const folderOpenIndex = this.openNodeIds.indexOf(folderId);
          if (folderOpenIndex == -1) {
            this.openNodeIds = [...this.openNodeIds, folderId];
          } else {
            this.openNodeIds.splice(folderOpenIndex, 1);
          }
        } else {
          // Toggle node open
          const indexToRemove = this.openNodeIds.indexOf(oldVal[0]);
          if(indexToRemove != -1){
            this.openNodeIds.splice(indexToRemove, 1);
          }
        }
        // Emit new selection
        this.$emit("onFolderSelection", folder);
        this.setSelectedFolder(folder)
      },
      deep: true,     
    },
  },
  methods: {
    ...mapActions(["fetchNodesNew", "getProjectByItemId", "setSelectedFolder"]),

    updateOpenNodes(newOpenNodeIds) {
      this.openNodeIds = newOpenNodeIds;
    },
    async getProjectRootFolders(projectData) {
      try {
        const nodes = await this.fetchNodesNew({ item: projectData });
        return nodes.map((nodeInfo) => VcadFolder.parseAccFolder(nodeInfo));
      } catch (error) {
        // @TODO: Implement error management
        console.log(`ERROR: Calle exited with error.\n${error}`);
      }
    },
    async loadSubFolder(folderItem) {
      if (!folderItem) {
        return;
      }
      try {
        let newChildren = await this.fetchNodesNew({
          item: folderItem,
        });
        newChildren = newChildren
          .filter((node) => node.type == "folders")
          .map((node) => ({
            id: node.id,
            itemId: node.itemId || node.id.split("/").at(-1),
            text: node.text,
            type: node.type,
            projectId: node.projectId,
            children: node.children || [],
          }));
        folderItem.children = newChildren;
      } catch (error) {
        // @TODO: Implement error management
        console.log(`ERROR: Calle exited with error.\n${error}`);
      }
    },
    async getOpenFolders(projectId, folderId) {
      const folderService = new FolderService();
      const folders = await folderService.getItemPathFolders(
        projectId,
        folderId
      );
      return folders;
    },
    async loadFolderTree() {
      this.projectIsLoading = true;
      let selectedProject = this.selectedProjectFromStore;

      if (this.targetFolderId && selectedProject && selectedProject.itemId) {
        try {
          const folderService = new FolderService();
          const openFolders = await this.getOpenFolders(
            selectedProject.itemId,
            this.targetFolderId
          );

          // Set folder List
          this.folders = folderService.folderListToTree(openFolders);

          // Set open folders
          this.openNodeIds = openFolders.map((folder) => folder.itemId);

          // Set active folders
          if (this.activeNodeIds[0] !== this.targetFolderId) {
            this.activeNodeIds = [this.targetFolderId];
          }
        } catch (error) {
          console.error(error);
          this.errorMsg =
            "Error in loading selected folder. Project root folder will be loaded instead.";
          this.showErrorMsg = true;
        }
      } else {
        this.folders = await this.getProjectRootFolders(selectedProject);
        // Set open folders
        this.openNodeIds = [];

        // Set active folders
        this.activeNodeIds = [];
      }
      this.projectIsLoading = false;
    },
  },
  async mounted() {
    // load folders
    this.loadFolderTree();
  },
};
</script>

<style lang="scss" scoped></style>
