var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"elevation-0",staticStyle:{"height":"calc(100vh - 160px)","overflow":"auto"}},[_c('v-card-title',{staticClass:"d-flex justify-center"},[(
        _vm.currentForgeFileData.b360Item.itemState.processState ==
        _vm.b360ElementStatusComplete
      )?_c('div',{ref:"actionBar",staticClass:"d-flex flex-wrap justify-center align-center elevation-0 action-bar",staticStyle:{"width":"100%"},attrs:{"dense":""}},[_c('v-btn',{staticClass:"vcad-btn file-action-btn",attrs:{"outlined":"","small":"","color":"primary","disabled":_vm.toolbarFileStatus == -2},on:{"click":function($event){return _vm.setShowPreview({ show: true, mode: 0 })}}},[_vm._v("View")]),(_vm.toolbarFileStatus == -1)?_c('vcad-import-dialog',{attrs:{"importFn":_vm.importVcad,"fileName":_vm.currentForgeFileData.fileName,"disableActivator":_vm.importingFile || _vm.toolbarFileStatus == -2,"importingFile":_vm.importingFile},model:{value:(_vm.importDialog),callback:function ($$v) {_vm.importDialog=$$v},expression:"importDialog"}}):_vm._e(),(_vm.toolbarFileStatus == 2)?_c('slot-version-changer',{attrs:{"slotGuid":_vm.slotChangerInfo.slotGuid,"fileId":_vm.slotChangerInfo.fileId,"fileType":_vm.slotChangerInfo.fileType,"projectId":_vm.slotChangerInfo.projectId,"currentVersionLabel":_vm.slotChangerInfo.currentVersionLabel}}):_vm._e(),(_vm.toolbarFileStatus == 2 || _vm.toolbarFileStatus == 3)?_c('vcad-dialog',{attrs:{"title":"Clear slot","contentProps":{
          fileName: _vm.currentForgeFileData.b360Item.displayName,
        }},scopedSlots:_vm._u([{key:"dialogActivator",fn:function(ref){
        var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"vcad-btn file-action-btn",attrs:{"outlined":"","small":"","color":"error darken-1","disabled":_vm.toolbarFileStatus == -2}},on),[_vm._v("Remove")])]}},{key:"content",fn:function(ref){
        var contentData = ref.contentData;
return [_c('p',{staticClass:"text-center"},[_vm._v(" You are about to clear the selected slot from the current loaded model : "),_c('i',[_c('b',[_vm._v(_vm._s(contentData.fileName))])])]),_c('v-alert',{attrs:{"border":"left","outlined":"","text":"","type":"warning","dense":""}},[_vm._v(" Previously generated reports and federations may not work correctly. Are you sure you wish to proceed? ")])]}},{key:"actions",fn:function(){return [_c('div',{staticClass:"d-inline-flex justify-end",staticStyle:{"width":"100%","height":"100%"}},[_c('v-btn',{staticClass:"vcad-btn",attrs:{"outlined":"","small":"","color":""},on:{"click":function($event){_vm.removeSlotDialog = false}}},[_vm._v("CLOSE")]),_c('v-btn',{staticClass:"vcad-btn",attrs:{"outlined":"","small":"","color":"primary"},on:{"click":_vm.removeVcadFile}},[_vm._v("CONFIRM")])],1)]},proxy:true}],null,false,2628814128),model:{value:(_vm.removeSlotDialog),callback:function ($$v) {_vm.removeSlotDialog=$$v},expression:"removeSlotDialog"}}):_vm._e(),(_vm.toolbarFileStatus == 2)?_c('v-btn',{staticClass:"vcad-btn file-action-btn",attrs:{"outlined":"","small":"","color":"primary"},on:{"click":function($event){$event.stopPropagation();return _vm.openTemplateSelector()}}},[_vm._v(" Templates ")]):_vm._e(),(_vm.toolbarFileStatus == 3 || _vm.toolbarFileStatus == 2)?_c('vcad-dialog',{attrs:{"title":"Reset status","contentProps":{
          fileName: _vm.currentForgeFileData.b360Item.displayName,
        }},scopedSlots:_vm._u([{key:"dialogActivator",fn:function(ref){
        var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"vcad-btn file-action-btn",attrs:{"outlined":"","small":"","color":"primary"}},on),[_vm._v("Restart conversion")])]}},{key:"content",fn:function(ref){
        var contentData = ref.contentData;
return [(_vm.enableProfilesVcad)?_c('div',{staticClass:"body-1 d-flex align-center justify-space-between"},[_c('div',{staticClass:"d-flex"},[_c('vcad-filter',{attrs:{"toApply":true,"fileType":contentData.fileName.split('.').pop()},on:{"closeFilterDialog":_vm.closeFilterDialog,"onSupportedTypesLoad":_vm.onSupportedTypesLoad},scopedSlots:_vm._u([{key:"filterDialogActivator",fn:function(ref){
        var on = ref.on;
return [_c('div',{staticClass:"d-flex align-center"},[_c('v-btn',_vm._g({staticClass:"vcad-btn",attrs:{"outlined":"","small":"","color":"primary","disabled":!_vm.allowedTypeCheck}},on),[_c('v-icon',{},[_vm._v("mdi-filter")]),_vm._v("Add filter")],1),(!_vm.allowedTypeCheck)?_c('div',{staticClass:"ml-2"},[_vm._v(" Filters are not available for the current file type ")]):_vm._e()],1)]}}],null,true),model:{value:(_vm.filterApply),callback:function ($$v) {_vm.filterApply=$$v},expression:"filterApply"}})],1),_c('div',{staticClass:"d-flex align-center"},[(_vm.allowedTypeCheck)?_c('b',[_vm._v(" "+_vm._s(!_vm.filterSet ? "No filter applied" : ("Filter applied: " + (_vm.filterApply.profileName)))+" ")]):_vm._e(),(_vm.filterSet)?_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.filterApply = null}}},[_c('v-icon',[_vm._v("mdi-close")])],1):_vm._e()],1)]):_vm._e(),_c('v-divider',{staticClass:"my-4"}),_c('p',{staticClass:"text-center"},[_vm._v(" You are about to restart the file conversione process. This operation can be useful if there has been some import error. ")]),_c('v-alert',{attrs:{"border":"left","outlined":"","text":"","type":"info","dense":""}},[_c('p',{staticClass:"text-center"},[_vm._v(" The slot used by this file will not change so the existing report will continue to work after a new import. ")])])]}},{key:"actions",fn:function(){return [_c('div',{staticClass:"d-inline-flex justify-end",staticStyle:{"width":"100%","height":"100%"}},[_c('v-btn',{staticClass:"vcad-btn",attrs:{"outlined":"","small":"","color":""},on:{"click":function($event){_vm.resetStatusDialog = false}}},[_vm._v("CLOSE")]),_c('v-btn',{staticClass:"vcad-btn",attrs:{"outlined":"","small":"","color":"primary"},on:{"click":_vm.resetVcadFileStatus}},[_vm._v("CONFIRM")])],1)]},proxy:true}],null,false,2554904964),model:{value:(_vm.resetStatusDialog),callback:function ($$v) {_vm.resetStatusDialog=$$v},expression:"resetStatusDialog"}}):_vm._e(),(
          _vm.currentForgeFileData.b360Item.fileType == 'rvt' ||
          _vm.currentForgeFileData.b360Item.fileType == 'dwg' ||
          _vm.currentForgeFileData.b360Item.fileType == 'dgn'
        )?_c('vcad-views-list-dialog'):_vm._e()],1):_vm._e()]),_c('v-divider'),_c('v-card-text',{ref:"ctext",style:(_vm.infoListStyle)},[(_vm.thumImgVisibility)?_c('v-img',{ref:"fileinfo_img",attrs:{"contain":"","height":_vm.thumbHeigth,"src":_vm.thumbData}}):_vm._e(),_c('v-expansion-panels',{staticClass:"mt-2",attrs:{"accordion":"","multiple":""},model:{value:(_vm.openPanels),callback:function ($$v) {_vm.openPanels=$$v},expression:"openPanels"}},[(_vm.currentForgeFileData.vcadItem)?_c('v-expansion-panel',[_c('v-expansion-panel-header',[_vm._v("Vcad Info")]),_c('v-expansion-panel-content',_vm._l((_vm.infoItems.filter(
              function (infoItem) { return infoItem.group == 'vcad'; }
            )),function(info){return _c('v-row',{key:info.group + '_' + info.propName,staticClass:"info-row"},[_c('v-col',{staticClass:"font-weight-bold pt-1 pb-1",attrs:{"cols":4}},[_vm._v(_vm._s(info.propDescr)+":")]),_c('v-col',{staticClass:"text-body-2 pt-1 pb-1",attrs:{"cols":8}},[_vm._v(_vm._s(_vm.currentForgeFileData[info.groupPropName] ? _vm.currentForgeFileData[info.groupPropName][info.propName] : null))])],1)}),1)],1):_vm._e(),_c('v-expansion-panel',[_c('v-expansion-panel-header',[_vm._v("Autodesk Construction Cloud"),_c('span',[_vm._v("®")]),_vm._v(" File info")]),_c('v-expansion-panel-content',_vm._l((_vm.infoItems.filter(
              function (infoItem) { return infoItem.group == 'b360'; }
            )),function(info){return _c('v-row',{key:info.group + '_' + info.propName,staticClass:"info-row"},[_c('v-col',{staticClass:"font-weight-bold pt-1 pb-1",attrs:{"cols":4}},[_vm._v(_vm._s(info.propDescr)+":")]),_c('v-col',{staticClass:"text-body-2 pt-1 pb-1",attrs:{"cols":8}},[_vm._v(_vm._s(_vm.currentForgeFileData[info.groupPropName] ? _vm.currentForgeFileData[info.groupPropName][info.propName] : null))])],1)}),1)],1),_c('v-expansion-panel',{staticClass:"mb-9"},[_c('v-expansion-panel-header',[_vm._v("Additional info")]),_c('v-expansion-panel-content',_vm._l((_vm.infoItems.filter(
              function (infoItem) { return infoItem.group == 'dev'; }
            )),function(info,devIndex){return _c('v-row',{key:info.group + '_' + info.propName + '_' + devIndex,staticClass:"info-row"},[_c('v-col',{staticClass:"font-weight-bold pt-1 pb-1",attrs:{"cols":4}},[_vm._v(_vm._s(info.propDescr)+":")]),(
                _vm.currentForgeFileData &&
                _vm.currentForgeFileData[info.groupPropName]
              )?_c('v-col',{staticClass:"text-body-2 pt-1 pb-1 elevation-0",attrs:{"cols":8}},[_vm._v(_vm._s(_vm.currentForgeFileData[info.groupPropName] ? _vm.currentForgeFileData[info.groupPropName][info.propName] : null))]):_vm._e()],1)}),1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }