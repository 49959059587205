<template>
  <div>
    <vcad-dialog dialogWidth="400" v-model="showDialog" title="Profile">
      <template v-slot:content="">
        <!-- <v-img
          max-width="300"
          max-height="300"
          class="align-end"
          height="200px"
          src="@/assets/vcadacc.png"
        >
          <v-card-title align="left" class="mt-8"> </v-card-title>
        </v-img> -->
        <div class="d-flex justify-center">
          <v-list-item-avatar>
            <img :src="userProfile.img" alt="" />
          </v-list-item-avatar>
        </div>

        <div class="">
          <v-divider class="pb-5" style="margin-top: -25px"></v-divider>
          <v-card-title class="justify-center">{{
            userProfile.name
          }}</v-card-title>
          <v-card-subtitle class="d-flex justify-center" color="secondary">
            <v-icon small>mdi-email</v-icon>
            <span class="ml-2">{{ userProfile.email }}</span>
          </v-card-subtitle>
        </div>

        <v-card-actions class="d-flex flex-column">
          <v-chip
            class=""
            :color="expirationDateColor"
            outlined
            v-if="trialMode !== 1"
          >
            Expires on: {{ vcadExpirationDate }}
          </v-chip>
          <v-chip
            :color="slotUsedCount == slotCount ? 'warning' : 'primary'"
            outlined
            class="my-3"
            medium
          >
            Slots: {{ slotUsedCount + "/" + slotCount }}
          </v-chip>
          <v-chip
            :color="subscriptionStatus == 'active' ? 'warning' : 'error'"
            outlined
            medium
            v-if="trialMode == 1"
          >
            Trial Mode (expires on: {{ vcadExpirationDate }})
          </v-chip>
          <!-- <v-chip
                  class=""
                  color="primary"
                  outlined
                  v-if="numImportingFiles > 0"
                >
                  Importing: {{ numImportingFiles }} files
                </v-chip> -->
        </v-card-actions>
      </template>
    </vcad-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import VcadDialog from "./VcadDialog.vue";
export default {
  props: {
    value: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      profileDialog: false,
    };
  },
  components: { VcadDialog },
  computed: {
    ...mapGetters([
      "slotUsedCount",
      "trialMode",
      "subscriptionStatus",
      "expirationDate",
      "slotCount",
      "vcadFileList",
      "userProfile",
    ]),
    showDialog: {
      get() {
        return this.value;
      },
      set(newVal) {
        this.updateValue(newVal);
      },
    },
    vcadExpirationDate() {
      let options = { dateStyle: "medium", timeStyle: "short", hour12: false };
      return new Date(this.expirationDate).toLocaleString(
        window.navigator.language,
        options
      );
    },
    expirationDateColor() {
      let expirationDate = new Date(this.vcadExpirationDate).getTime();
      let today = new Date().getTime();
      let daysLeft = expirationDate - today;
      let difference = Math.floor(daysLeft / (1000 * 60 * 60 * 24));
      return difference <= 10 ? "red" : "primary";
    },
  },
  methods: {
    updateValue(newVal) {
      this.$emit("input", newVal);
    },
    numImportingFiles() {
      return this.vcadFileList.reduce((prev, curr) => {
        const importingStats = [0, 1];
        return importingStats.includes(curr.status) ? prev + 1 : prev;
      }, 0);
    },
  },
};
</script>

<style lang="scss" scoped>
</style>