
/**
 * Convert an array of objects into cvs string
 * @param {Array} list Array of objects to convert into csv
 * @param {string} separatorChar Separator character
 * @param {Array} propList Array of properties to extract
 * @returns Return csv string of list
 */
export function toCsv(list, {separatorChar, propList}){
    // Setup separator character
    const separator = separatorChar || ',';

    // Calc headers
    const distinctHeaders = propList ? propList : list.reduce((distinct, curr) => {
        const currHeader = Object.keys(curr);
        const tmp = new  Set([...distinct, ...currHeader]);
        return [...tmp];
    }, []);

    // Calc rows
    const rows = list.map( row => {
        return distinctHeaders.map( header => {
            const propValue = row[header];
            const retStr = propValue;//typeof propValue == 'object' ? `${JSON.stringify(propValue)}` : propValue;
            return JSON.stringify(retStr || '');
        }).join(separator);
    })

    // Carc CSV
    const csv = [
        distinctHeaders.join(separator),
        ...rows
    ].join('\r\n');

    return csv;
}

export function downloadCsv(list, options,name){
    const {separatorChar, propList} = options;
    // Create csv string
    const csv = toCsv(list, {separatorChar, propList});
    
    // Create file anchor
    const anchor = document.createElement('a');
    anchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv);
    anchor.target = '_blank';
    if(name){
        anchor.download = name;
    }else{
        anchor.download = 'nameYourFileHere.csv';
    }

    // Download file
    anchor.click();

    // Destroy anchor
    anchor.remove();
}
