import axios from "axios";
// import {
//     getFileStatus
// } from "../utils/VCadItemStatusUtil";
// import {
//     CheckOrCreateUser
// } from "../utils/CheckOrCreateUser";
// import router from '../router/index';
const namespaced = false;

const state = {
    folderMMProjects: [],
    userMMProjects: [],
    selectedMMProject: null,
    isModifiedMM: false,
    templateIdToAdd: 1000,
    projectSaveStatus: 'notToSave',
    mmExtCompatibilityGroup: { ifc: 11, ifczip: 11, nwc: 5, nwd: 5, rvt: 8 },
    mmFiles: [],
    mmSlotsNotFound: [],
    mmAlignmentData: [],
};

const getters = {
    mmAlignmentData: state => {
        return state.mmAlignmentData;
    },
    mmSlotsNotFound: state => {
        return state.mmSlotsNotFound;
    },
    mmFiles: state => {
        return state.mmFiles;
    },
    mmExtCompatibilityGroup: state => {
        return state.mmExtCompatibilityGroup;
    },
    projectSaveStatus: state => {
        return state.projectSaveStatus;
    },
    templateIdToAdd: state => {
        return state.templateIdToAdd;
    },
    folderMMProjects: state => {
        return state.folderMMProjects;
    },
    userMMProjects: state => {
        return state.userMMProjects;
    },
    selectedMMProject: state => {
        return state.selectedMMProject;
    },
    isModifiedMM: state => {
        return state.isModifiedMM;
    },
    selectedFolderMMGUID: (state, getters, rootState, rootGetters) => {
        if (!rootGetters.selectedFolder) {
            return null
        }
        return btoa(rootGetters.selectedFolder.id);
    },
};

const mutations = {
    SET_MM_ALIGNMENT_DATA(state, payload) {
        state.mmAlignmentData = payload;
    },
    SET_MMFiles(state, payload) {
        state.mmFiles = payload;
    },
    SET_MMSLOTS_NOT_FOUND(state, payload) {
        state.mmSlotsNotFound = payload;
    },
    SET_PROJECT_SAVE_STATUS(state, payload) {
        state.projectSaveStatus = payload;
    },
    SET_FOLDER_MMPROJECTS(state, payload) {
        state.folderMMProjects = payload;
    },
    SET_USER_MMPROJECTS(state, payload) {
        state.userMMProjects = payload;
    },
    SET_SELECTED_MMPROJECT(state, payload) {
        state.selectedMMProject = payload;
    },

    SET_IS_MODIFIED_MM(state, payload) {
        state.isModifiedMM = payload;
    },

};

const actions = {

    setMMSlotsNotFound({
        commit
    }, data) {
        commit("SET_MMSLOTS_NOT_FOUND", data);
    },
    setMMFiles({
        commit
    }, data) {
        commit("SET_MMFiles", data);
    },
    setProjectSaveStatus({
        commit
    }, data) {
        commit("SET_PROJECT_SAVE_STATUS", data);

    },
    updSelectedMMPrjSlot({
        commit, getters
    }, slotList) {
        let tmpSelectedMMP = { ...getters.selectedMMProject }
        tmpSelectedMMP.slots = [...slotList]
        commit("SET_SELECTED_MMPROJECT", tmpSelectedMMP)
    },

    setSelectedMMProject({
        commit
    }, data) {
        commit("SET_SELECTED_MMPROJECT", data);
    },
    setIsModifiedMM({
        commit
    }, data) {
        commit("SET_IS_MODIFIED_MM", data);
    },

    setMMAlignmentData(context, data) {
        let settings = JSON.parse(JSON.stringify(context.getters.axiosSettings));
        settings["params"] = data;
        return new Promise((resolve, reject) => {
            axios.get("api/vcad/multimodel/getalignmentdata", settings).then(
                (result) => {
                    context.commit("SET_MM_ALIGNMENT_DATA", result.data);
                    resolve(result.data);
                },
                (error) => {
                    reject(error);
                }
            );
        });
    },

    setFolderMMProjects(context, data) {
        let settings = JSON.parse(JSON.stringify(context.getters.axiosSettings));
        settings["params"] = data;

        return axios.get("api/vcad/multimodel/getfolderprojects", settings).then(
            (result) => {
                let arr = result.data.map((m) => {
                    return JSON.parse(m);
                });
                context.commit("SET_FOLDER_MMPROJECTS", arr);
                return result.data;
            }
        );
    },
    setUserMMProjects(context) {
        let settings = JSON.parse(JSON.stringify(context.getters.axiosSettings));
        return axios.get("api/vcad/multimodel/getuserprojects", settings).then(
            (result) => {
                context.commit("SET_USER_MMPROJECTS", result.data);
                return result.data;
            }
        );
    },
    /**
     * Add a new federation to the list of folder projects.
     */
    addToFolderMMProjects(context, newFederation) {
        let tmpList = [...context.getters.folderMMProjects, newFederation];
        context.commit("SET_FOLDER_MMPROJECTS", tmpList);
    },
    updateFolderMMProjects({commit}, newFedertionList){
        commit("SET_FOLDER_MMPROJECTS", [...newFedertionList]);
    },

    deleteMMProject(context, project) {
        let settings = JSON.parse(JSON.stringify(context.getters.axiosSettings));
        settings["params"] = { projectGuid: project.multimodelProjectGuid };
        return new Promise((resolve, reject) => {
            axios.delete("api/vcad/multimodel/deleteProject", settings).then(
                (result) => {

                    resolve(result.data);
                },
                (error) => {
                    reject(error);
                }
            );
        });

    },
    saveUpdateMMProject(context, {federationData, projectId, folderItemId}) {
        // Parse federation slots
        const parsedSlots = federationData.slots.map((slotData)=> ({...slotData, View:slotData.selectedView.guid}))
        federationData.slots = [...parsedSlots];

        let settings = JSON.parse(JSON.stringify(context.getters.axiosSettings));
        settings.headers["Content-Type"] = "application/json";
        return axios.post("api/vcad/multimodel/saveMultimodelProject", {federationData, projectId, folderItemId}, settings)
        .then((result) => {
            if(result.status == 200){
                return result.data
            }
        })
        .catch((err) => {
            console.error(err);
            throw('Error in creating or updating federation.');
        });
    },
};

const multimodel = {
    namespaced,
    state,
    actions,
    getters,
    mutations
}

export default multimodel;