<template>
  <v-card
    style="height: calc(100vh - 160px); overflow: auto"
    class="elevation-0"
  >
    <v-card-title class="d-flex justify-center">
      <!-- Status: {{JSON.stringify(currentForgeFileData.b360Item.itemState.processState != b360ElementStatusComplete)}} -->
      <!-- {{currentForgeFileData}} -->
      <!-- ACTIONS -->
      <div
        dense
        style="width: 100%"
        class="d-flex flex-wrap justify-center align-center elevation-0 action-bar"
        ref="actionBar"
        v-if="
          currentForgeFileData.b360Item.itemState.processState ==
          b360ElementStatusComplete
        "
      >
        <!-- PREVIEW -->
        <v-btn
          outlined
          small
          color="primary"
          class="vcad-btn file-action-btn"
          @click="setShowPreview({ show: true, mode: 0 })"
          :disabled="toolbarFileStatus == -2"
          >View</v-btn
        >

        <!-- IMPORT -->
        <vcad-import-dialog
          v-model="importDialog"
          :importFn="importVcad"
          :fileName="currentForgeFileData.fileName"
          :disableActivator="importingFile || toolbarFileStatus == -2"
          :importingFile="importingFile"
          v-if="toolbarFileStatus == -1"
        ></vcad-import-dialog>

        <!-- SLOT RETARGET -->
        <slot-version-changer
          :slotGuid="slotChangerInfo.slotGuid"
          :fileId="slotChangerInfo.fileId"
          :fileType="slotChangerInfo.fileType"
          :projectId="slotChangerInfo.projectId"
          :currentVersionLabel="slotChangerInfo.currentVersionLabel"
          v-if="toolbarFileStatus == 2"
        ></slot-version-changer>

        <!-- REMOVE -->
        <vcad-dialog
          v-model="removeSlotDialog"
          title="Clear slot"
          :contentProps="{
            fileName: currentForgeFileData.b360Item.displayName,
          }"
          v-if="toolbarFileStatus == 2 || toolbarFileStatus == 3"
        >
          <template v-slot:dialogActivator="{ on }">
            <v-btn
              v-on="on"
              outlined
              small
              color="error darken-1"
              class="vcad-btn file-action-btn"
              :disabled="toolbarFileStatus == -2"
              >Remove</v-btn
            >
          </template>

          <template v-slot:content="{ contentData }">
            <p class="text-center">
              You are about to clear the selected slot from the current loaded
              model :
              <i
                ><b>{{ contentData.fileName }}</b></i
              >
            </p>
            <v-alert border="left" outlined text type="warning" dense>
              Previously generated reports and federations may not work
              correctly. Are you sure you wish to proceed?
            </v-alert>
          </template>

          <!-- ACTIONS -->
          <template v-slot:actions>
            <div
              class="d-inline-flex justify-end"
              style="width: 100%; height: 100%"
            >
              <v-btn
                outlined
                small
                color=""
                class="vcad-btn"
                @click="removeSlotDialog = false"
                >CLOSE</v-btn
              >
              <v-btn
                outlined
                small
                color="primary"
                class="vcad-btn"
                @click="removeVcadFile"
                >CONFIRM</v-btn
              >
            </div>
          </template>
        </vcad-dialog>

        <!-- TEMPLATE SELECTION -->
        <v-btn
          outlined
          small
          color="primary"
          class="vcad-btn file-action-btn"
          @click.stop="openTemplateSelector()"
          v-if="toolbarFileStatus == 2"
        >
          Templates
        </v-btn>

        <!-- RESET STATUS -->
        <vcad-dialog
          v-model="resetStatusDialog"
          title="Reset status"
          :contentProps="{
            fileName: currentForgeFileData.b360Item.displayName,
          }"
          v-if="toolbarFileStatus == 3 || toolbarFileStatus == 2"
        >
          <template v-slot:dialogActivator="{ on }">
            <v-btn
              v-on="on"
              outlined
              small
              color="primary"
              class="vcad-btn file-action-btn"
              >Restart conversion</v-btn
            >
          </template>

          <template v-slot:content="{contentData}">
            <!-- FILTER -->
            <div
              class="body-1 d-flex align-center justify-space-between"
              v-if="enableProfilesVcad"
            >
              <div class="d-flex">
                <vcad-filter
                  :toApply="true"
                  v-model="filterApply"
                  :fileType="contentData.fileName.split('.').pop()"
                  @closeFilterDialog="closeFilterDialog"
                  @onSupportedTypesLoad="onSupportedTypesLoad"
                >
                  <template v-slot:filterDialogActivator="{ on }">
                    <div class="d-flex align-center">
                      <v-btn
                        outlined
                        small
                        color="primary"
                        class="vcad-btn"
                        v-on="on"
                        :disabled="!allowedTypeCheck"
                      >
                        <v-icon class="">mdi-filter</v-icon>Add filter</v-btn
                      >
                      <div class="ml-2" v-if="!allowedTypeCheck">
                        Filters are not available for the current file type
                      </div>
                    </div>
                  </template>
                </vcad-filter>
              </div>
              <div class="d-flex align-center">
                <b v-if="allowedTypeCheck">
                  {{
                    !filterSet
                      ? "No filter applied"
                      : `Filter applied: ${filterApply.profileName}`
                  }}
                </b>
                <!-- <v-icon
                            v-if="filterSet"
                            small
                            class="ml-1"
                            color="success"
                            >mdi-check-bold</v-icon
                          > -->
                <v-btn
                  class=""
                  v-if="filterSet"
                  icon
                  @click="filterApply = null"
                >
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </div>
            </div>
            <v-divider class="my-4"></v-divider>
            <p class="text-center">
              You are about to restart the file conversione process. This
              operation can be useful if there has been some import error.
            </p>
            <v-alert border="left" outlined text type="info" dense>
              <p class="text-center">
                The slot used by this file will not change so the existing
                report will continue to work after a new import.
              </p>
            </v-alert>
          </template>

          <!-- ACTIONS -->
          <template v-slot:actions>
            <div
              class="d-inline-flex justify-end"
              style="width: 100%; height: 100%"
            >
              <v-btn
                outlined
                small
                color=""
                class="vcad-btn"
                @click="resetStatusDialog = false"
                >CLOSE</v-btn
              >
              <v-btn
                outlined
                small
                color="primary"
                class="vcad-btn"
                @click="resetVcadFileStatus"
                >CONFIRM</v-btn
              >
            </div>
          </template>
        </vcad-dialog>
        <!-- VIEWS LIST -->
        <vcad-views-list-dialog
          v-if="
            currentForgeFileData.b360Item.fileType == 'rvt' ||
            currentForgeFileData.b360Item.fileType == 'dwg' ||
            currentForgeFileData.b360Item.fileType == 'dgn'
          "
        ></vcad-views-list-dialog>
      </div>
    </v-card-title>
    <v-divider></v-divider>
    <!-- FILE INFO -->
    <v-card-text :style="infoListStyle" ref="ctext">
      <!-- MODEL THUMBNAIL -->
      <v-img
        ref="fileinfo_img"
        contain
        :height="thumbHeigth"
        :src="thumbData"
        v-if="thumImgVisibility"
      ></v-img>

      <v-expansion-panels class="mt-2" v-model="openPanels" accordion multiple>
        <!-- VCAD INFO -->
        <v-expansion-panel v-if="currentForgeFileData.vcadItem">
          <v-expansion-panel-header>Vcad Info</v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-row
              v-for="info in infoItems.filter(
                (infoItem) => infoItem.group == 'vcad'
              )"
              :key="info.group + '_' + info.propName"
              class="info-row"
            >
              <v-col :cols="4" class="font-weight-bold pt-1 pb-1"
                >{{ info.propDescr }}:</v-col
              >
              <v-col :cols="8" class="text-body-2 pt-1 pb-1">{{
                currentForgeFileData[info.groupPropName]
                  ? currentForgeFileData[info.groupPropName][info.propName]
                  : null
              }}</v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <!-- BIM360 INFO -->
        <v-expansion-panel>
          <v-expansion-panel-header
            >Autodesk Construction Cloud<span>&#174;</span> File
            info</v-expansion-panel-header
          >
          <v-expansion-panel-content>
            <v-row
              v-for="info in infoItems.filter(
                (infoItem) => infoItem.group == 'b360'
              )"
              :key="info.group + '_' + info.propName"
              class="info-row"
            >
              <v-col :cols="4" class="font-weight-bold pt-1 pb-1"
                >{{ info.propDescr }}:</v-col
              >
              <v-col :cols="8" class="text-body-2 pt-1 pb-1">{{
                currentForgeFileData[info.groupPropName]
                  ? currentForgeFileData[info.groupPropName][info.propName]
                  : null
              }}</v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <!-- DEV INFO -->
        <v-expansion-panel class="mb-9">
          <v-expansion-panel-header>Additional info</v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-row
              v-for="(info, devIndex) in infoItems.filter(
                (infoItem) => infoItem.group == 'dev'
              )"
              :key="info.group + '_' + info.propName + '_' + devIndex"
              class="info-row"
            >
              <v-col :cols="4" class="font-weight-bold pt-1 pb-1"
                >{{ info.propDescr }}:</v-col
              >
              <v-col
                :cols="8"
                class="text-body-2 pt-1 pb-1 elevation-0"
                v-if="
                  currentForgeFileData &&
                  currentForgeFileData[info.groupPropName]
                "
                >{{
                  currentForgeFileData[info.groupPropName]
                    ? currentForgeFileData[info.groupPropName][info.propName]
                    : null
                }}</v-col
              >
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-card-text>
  </v-card>
</template>


<script>
import { mapGetters, mapActions } from "vuex";
import axios from "axios";
import EventBus from "../event-bus";
import VcadImportDialog from "./VcadImportDialog";
import VcadDialog from "./VcadDialog";
import SlotVersionChanger from "./SlotVersionChanger";
//import VcadSnackbar from "./VcadSnackbar";
import VcadViewsListDialog from "./VcadViewsListDialog.vue";
import VcadFilter from "../components/filters/FilterProfileDialog.vue";


export default {
  components: {
    "vcad-import-dialog": VcadImportDialog,
    "vcad-dialog": VcadDialog,
    "slot-version-changer": SlotVersionChanger,
    "vcad-views-list-dialog": VcadViewsListDialog,
    "vcad-filter": VcadFilter,
  },
  data() {
    return {
      removeSlotDialog: false,
      resetStatusDialog: false,
      importDialog: false,
      importingFile: false,
      thumbData: null,
      thumImgIsLoaded: false,
      showDevinfo: false,
      filterApply: null,
      allowedTypeCheck: false,
      infoItemsTemplate: [
        {
          group: "b360",
          groupPropName: "b360Item",
          propName: "displayName",
          propDescr: "Display name",
        },
        {
          group: "b360",
          groupPropName: "b360Item",
          propName: "type",
          propDescr: "Type",
        },
        {
          group: "b360",
          groupPropName: "b360Item",
          propName: "revisionDisplayLabel",
          propDescr: "Version",
        },
        {
          group: "b360",
          groupPropName: "b360Item",
          propName: "sourceFileName",
          propDescr: "Source file name",
        },
        {
          group: "dev",
          groupPropName: "b360Item",
          propName: "itemId",
          propDescr: "Item ID",
        },
        {
          group: "dev",
          groupPropName: "b360Item",
          propName: "projectId",
          propDescr: "Project ID",
        },
        {
          group: "b360",
          groupPropName: "b360Item",
          propName: "viewableName",
          propDescr: "Viewable name",
        },
        {
          group: "dev",
          groupPropName: "b360Item",
          propName: "viewableId",
          propDescr: "Viewable ID",
        },
        {
          group: "dev",
          groupPropName: "b360Item",
          propName: "viewableGuid",
          propDescr: "Viewable GUID",
        },
        {
          group: "dev",
          groupPropName: "b360Item",
          propName: "derivativeUrn",
          propDescr: "Derivative Urn",
        },
        {
          group: "dev",
          groupPropName: "vcadItem",
          propName: "id",
          propDescr: "Slot ID",
        },
        {
          group: "dev",
          groupPropName: "vcadItem",
          propName: "viewableId",
          propDescr: "Vcad viewable GUID",
        },
        {
          group: "dev",
          groupPropName: "b360Item",
          propName: "version",
          propDescr: "Internal version",
        },
        {
          group: "dev",
          groupPropName: "vcadItem",
          propName: "error",
          propDescr: "Vcad import error",
        },
        {
          group: "vcad",
          groupPropName: "vcadItem",
          propName: "dateEndConv",
          propDescr: "Vcad import date",
        },
        // {
        //   group: "dev",
        //   groupPropName: "vcadItem",
        //   propName: "viewableGuid",
        //   propDescr: "Vcad viewableGuid",
        // },
      ],
      actionBarHeight: 0,
      tmpActionBar: null,
      b360ElementStatusComplete: "PROCESSING_COMPLETE",
      openPanels: [0],
    };
  },
  watch: {
    toolbarFileStatus(value) {
      if (value == 0) {
        this.importingFile = false;
      }
      if (value == 1) {
        this.importingFile = true;
      }

      //Update ActionBar size
      this.$nextTick().then(() => {
        this.actionBarHeight = this.tmpActionBar.clientHeight;
      });
    },
    b360Item: {
      deep: true,
      handler() {
        if (
          this.currentForgeFileData.b360Item.versionType == "bim360documents" &&
          this.currentForgeFileData.b360Item.derivativeUrn == null
        ) {
          return;
        }

        this.importingFile == false;
        this.thumImgIsLoaded = false;
        this.setThums();
      },
    },
  },
  computed: {
    ...mapGetters([
      "forgeToken",
      "currentForgeFileData",
      "vCadFileStatus",
      "userProfile",
      "selectedProject",
      "vcadUserInfo",
    ]),
    getConfigurationUserVcad() {
      try {
        return JSON.parse(this.vcadUserInfo);
      } catch (error) {
        return null;
      }
    },
    enableProfilesVcad() {
      try {
        return this.getConfigurationUserVcad.profilesEnable;
      } catch (error) {
        return null;
      }
    },
    filterSet() {
      if (this.filterApply) {
        return true;
      } else {
        return false;
      }
    },
    // infoPanelHeight() {
    //   // console.log("fileSplitPanelHeight");
    //   let h = this.$root.$el.clientHeight;
    //   // return h - 500;
    //   return h - 200;
    // },

    b360Item() {
      return this.currentForgeFileData.b360Item;
    },
    toolbarFileStatus() {
      let fs = this.vCadFileStatus;
      return fs;
    },
    /**
     * Height of preview thumbnail.
     */
    thumbHeigth() {
      let screenSize = this.$vuetify.breakpoint.name;
      let h = "150px";

      if (screenSize == "xl") {
        h = "300px";
      }
      return h;
    },
    /**
     * Preview thumbnail visibility.
     * Only show image if it is loaded the screen size is 'lg' or 'xl'.
     */
    thumImgVisibility() {
      let screenSize = this.$vuetify.breakpoint.name;
      if (screenSize != "lg" && screenSize != "xl") {
        return false;
      }
      return this.thumImgIsLoaded;
    },
    infoListStyle() {
      let style = {
        // height:'80%',
        overflow: "auto",
      };
      // style.height = `calc(100% - ${this.actionBarHeight + 40}px)`;
      let offset = this.actionBarHeight;
      style.height = `calc(100% - ${offset}px)`;
      return style;
    },
    /**
     * Informationrequired by ther Slot Changer component.
     */
    slotChangerInfo() {
      let ret = {};

      const projectUID = this.selectedProject.id.split("/").pop();
      // Check if current file is an item or a version
      if (this.currentForgeFileData.b360Item.type == "versions") {
        ret.fileId = this.currentForgeFileData.b360Item.sourceItemIdForVersion;
        ret.fileType = this.currentForgeFileData.b360Item.versionType;
      } else {
        // Compose return object
        ret.fileId = this.currentForgeFileData.b360Item.itemId;
        ret.fileType = this.currentForgeFileData.b360Item.type;
      }

      ret.slotGuid = this.currentForgeFileData.vcadItem.id;
      ret.projectId = projectUID;
      ret.currentVersionLabel = `V${this.currentForgeFileData.b360Item.revisionDisplayLabel} (${this.currentForgeFileData.b360Item.lastUpdateDate})`;

      return ret;
    },
    /**
     * Properties to display
     */
    infoItems() {
      let ret = [...this.infoItemsTemplate];

      // Remove error info if there is no error
      if (!this.currentForgeFileData.error) {
        ret.filter((infoItem) => {
          let isErrorItem =
            infoItem.group == "dev" && infoItem.propName == "error";
          return !isErrorItem ? infoItem : undefined;
        });
      }

      return ret;
    },
  },
  methods: {
    ...mapActions([
      "setShowPreview",
      "setVcadImport",
      "setShowTemplates",
      "deleteVcadFile",
      "setCurrentViews",
      "setVcadTemplateList",
      "restartConversion",
    ]),
    onSupportedTypesLoad(supportedTypes) {
      this.allowedTypeCheck = supportedTypes.length > 0;
    },
    closeFilterDialog(value) {
      this.filterDialog = value;
    },
    /**
     * Delete slot.
     */
    removeVcadFile() {
      this.deleteVcadFile({ guid: this.currentForgeFileData.vcadItem.id })
        .then(() => {
          this.removeSlotDialog = false;
        })
        .catch((err) => {
          console.error(err);
        });
    },

    /**
     * Reset file import status, keeping link to current slot.
     */
    resetVcadFileStatus() {
      this.restartConversion({
        slotGuid: this.currentForgeFileData.vcadItem.id,
        filterId: this.filterApply?.profileId,
      }).finally(() => {
        this.resetStatusDialog = false;
      });
    },
    /**
     * Import file into Vcad occupying a new slot.
     */
    importVcad(filterId) {
      if (this.importingFile == true) return;
      // console.log("importvcad_fileinfo");
      this.importingFile = true;
      let email = this.userProfile.email;

      let info = {
        userId: this.userProfile.id,
        email: email,
        itemId: this.currentForgeFileData.b360Item.itemId,
        fileName: this.currentForgeFileData.fileName,
        viewableId: this.currentForgeFileData.b360Item.viewableId,
        viewableGuid: this.currentForgeFileData.b360Item.viewableGuid,
        viewableName: this.currentForgeFileData.b360Item.viewableName,
        b360Type: this.currentForgeFileData.b360Item.type,
        b360Item: this.currentForgeFileData.b360Item,
      };

      this.setVcadImport({
        email: email,
        fileName: this.currentForgeFileData.fileName,
        // urn: this.currentForgeFileData.urn,
        urn: this.currentForgeFileData.b360Item.derivativeUrn,
        viewableGuid: this.currentForgeFileData.b360Item.viewableGuid,
        viewableName: this.currentForgeFileData.b360Item.viewableName,
        info: info,
        filterId: filterId,
      })
        .then(() => {
          this.importingFile = false;
        })
        .catch(() => {
          this.importingFile = false;
          // console.log(err);
        });
    },

    setThums() {
      const th = this;

      let url = "";
      url =
        "https://developer.api.autodesk.com/derivativeservice/v2/thumbnails/" +
        th.currentForgeFileData.b360Item.derivativeUrn +
        "?type=medium";

      if (th.currentForgeFileData.b360Item.viewableGuid) {
        url = url + "&guid=" + th.currentForgeFileData.b360Item.viewableGuid;
      } else if (th.currentForgeFileData.viewableGuid) {
        url = url + "&guid=" + th.currentForgeFileData.viewableGuid;
      }

      axios
        .get(url, {
          headers: {
            Authorization: "Bearer " + this.forgeToken.access_token,
          },
          responseType: "arraybuffer",
        })
        .then((response) => {
          this.thumImgIsLoaded = true;
          let str = new Buffer(response.data, "binary").toString("base64");

          this.thumbData = "data:image/jpeg;base64," + str;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    /**
     * Setup current views and open template dialog
     */
    async openTemplateSelector() {
      // Load and set current views
      await this.setCurrentViews(this.currentForgeFileData.b360Item);

      // Set template list
      await this.setVcadTemplateList(this.currentForgeFileData.vcadItem.type);

      // Open template selector component
      this.setShowTemplates(true);
    },
  },
  mounted() {
    this.tmpActionBar = this.$refs.actionBar;
    // this.actionBarHeight = this.tmpActionBar.clientHeight;

    //Update ActionBar size
    this.$nextTick().then(() => {
      this.actionBarHeight = this.tmpActionBar.clientHeight;
    });

    //Setup events
    EventBus.$on("windowResizeEvent", () => {
      if (this.tmpActionBar) {
        this.actionBarHeight = this.tmpActionBar.clientHeight;
      }
    });
    EventBus.$on("mainPanelVResizeEvent", () => {
      if (this.tmpActionBar) {
        this.actionBarHeight = this.tmpActionBar.clientHeight;
      }
    });

    this.setThums();
  },
};
</script>
<style lang="css" scoped>
.info-row {
  border-top: solid 1px rgba(200, 200, 200, 0.25);
}
</style>