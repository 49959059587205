<template>
  <vcad-dialog
    v-model="showDialog"
    title="Import Options"
    dialogWidth="800"
    :contentProps="{ fileName: fileName }"
  >
    <!-- ACTIVATOR -->
    <template v-slot:dialogActivator="{ on }">
      <v-btn
        v-on="on"
        outlined
        small
        color="primary"
        class="vcad-btn file-action-btn"
        >Import</v-btn
      >
    </template>

    <!-- CONTENT -->
    <template v-slot:content="{ contentData }">
      <!-- FILTER -->
      <div
        class="body-1 d-flex align-center justify-space-between"
        v-if="enableProfilesVcad"
      >
        <div class="d-flex">
          <vcad-filter
            :toApply="true"
            v-model="filterApply"
            :fileType="contentData.fileName.split('.').pop()"
            @closeFilterDialog="closeFilterDialog"
            @onSupportedTypesLoad="onSupportedTypesLoad"
          >
            <template v-slot:filterDialogActivator="{ on }">
              <div class="d-flex align-center">
                <v-btn
                  outlined
                  small
                  color="primary"
                  class="vcad-btn"
                  v-on="on"
                  :disabled="!allowedTypeCheck"
                >
                  <v-icon class="">mdi-filter</v-icon>Add filter</v-btn
                >
                <div class="ml-2" v-if="!allowedTypeCheck">
                  Filters are not available for the current file type
                </div>
              </div>
            </template>
          </vcad-filter>
        </div>
        <div class="d-flex align-center">
          <b v-if="allowedTypeCheck">
            {{
              !filterSet
                ? "No filter applied"
                : `Filter applied: ${filterApply.profileName}`
            }}
          </b>
          <v-btn class="" v-if="filterSet" icon @click="filterApply = null">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
      </div>
      <v-divider class="my-4"></v-divider>
      <div>
        <div class="body-1" v-if="slotCount >= slotUsedCount">
          <p class="mt-4">
            You are about to import the selected file<span
              v-if="contentData.fileName"
              >:
              <i
                ><b>{{ contentData.fileName }}</b></i
              ></span
            >. This operation will occupy one of your slots.
          </p>
        </div>
        <div v-else>
          <p class="body-1">
            Sorry, you seem to have run out of available slots and cannot import
            new files.
          </p>
          <v-alert border="left" outlined text type="warning" dense>
            <p>
              <b>NOTE:</b> If you whish to import a new file you will first have
              to clear an old slot. This will make the old reports you created
              with that slot, unusable.
            </p>
          </v-alert>
          <v-alert border="left" outlined text type="info" dense>
            <p class="mt-2">
              If you require more slots
              <a href="https://www.bimservices.it/contact/" target="_blank"
                >contact our support staff</a
              >
              to change your subscription plan.
            </p>
          </v-alert>
        </div>
      </div>

      <!-- <v-checkbox
        label="Auto Update"
        ></v-checkbox> -->

      <!-- <p>Are you sure you want to proceed?</p> -->
    </template>

    <!-- ACTIONS -->
    <template v-slot:actions>
      <div class="d-inline-flex justify-end" style="width: 100%; height: 100%">
        <v-btn
          outlined
          small
          color=""
          class="vcad-btn"
          @click="showDialog = false"
          >CLOSE</v-btn
        >
        <v-btn
          outlined
          small
          color="primary"
          class="vcad-btn"
          @click="importModel"
          :disabled="slotCount <= slotUsedCount"
          >CONFIRM</v-btn
        >
      </div>
    </template>
  </vcad-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import VcadDialog from "./VcadDialog";
import filterProfileDialog from "../components/filters/FilterProfileDialog.vue";
export default {
  props: {
    /**
     * Model to be passed as v-model of v-dialog.
     */
    value: {
      type: Boolean,
      required: false,
    },
    /**
     * Function to call to import the file into Vcad
     */
    importFn: {
      type: Function,
      required: true,
    },
    /**
     * Name of file to import
     */
    fileName: {
      type: String,
      required: false,
      default: null,
    },
    /**
     * Disables the activato button.
     */
    disableActivator: {
      type: Boolean,
      required: false,
      default: false,
    },
    /**
     * Displays progress circle if file is being imported.
     */
    importingFile: {
      type: Boolean,
      required: false,
      default: false,
    },
    filter: {
      type: [Object, Array],
      required: false,
    },
  },
  components: {
    "vcad-dialog": VcadDialog,
    "vcad-filter": filterProfileDialog,
  },
  data() {
    return {
      filterApply: null,
      allowedTypeCheck: false,
    };
  },
  computed: {
    ...mapGetters(["slotCount", "slotUsedCount", "vcadUserInfo"]),
    showDialog: {
      get() {
        return this.value;
      },
      set(newVal) {
        this.updateValue(newVal);
      },
    },
    filterSet() {
      if (this.filterApply) {
        return true;
      } else {
        return false;
      }
    },
    getConfigurationUserVcad() {
      try {
        return JSON.parse(this.vcadUserInfo);
      } catch (error) {
        return null;
      }
    },
    enableProfilesVcad() {
      try {
        return this.getConfigurationUserVcad.profilesEnable;
      } catch (error) {
        return null;
      }
    },
  },
  methods: {
    /**
     * Vcad filters methods
     */
    onSupportedTypesLoad(supportedTypes) {
      this.allowedTypeCheck = supportedTypes.length > 0;
    },
    closeFilterDialog(value) {
      this.filterDialog = value;
    },
    /**
     * Emit update of v-model to parent component.
     * @param {Boolean} newVal new value to emit
     */
    updateValue(newVal) {
      this.$emit("input", newVal);
    },
    /**
     * Import Model and close dialog.
     */
    importModel() {
      let filterId = this.filterApply ? this.filterApply?.profileId : null;
      // Run import function
      this.importFn(filterId);

      //Close dialog by setting dialog model to false.
      this.updateValue(false);
    },
  },
  mounted() {
    this.filterApply = this.filter;
  },
};
</script>

<style lang="scss" scoped>
</style>