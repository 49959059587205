var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-select',{staticClass:"mt-1",attrs:{"label":"View","return-object":"","item-text":"name","item-key":"guid","items":_vm.vcadCurrentViews,"outlined":"","dense":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.name))]),_c('span',{staticClass:"grey--text text-caption ml-10"},[_vm._v(" ( "+_vm._s(_vm.vcadCurrentViews.length)+" views) ")])]}}]),model:{value:(_vm.selectedView),callback:function ($$v) {_vm.selectedView=$$v},expression:"selectedView"}}),_c('v-data-table',{staticClass:"elevation-5 v-table-scrollable",attrs:{"headers":_vm.headers,"items":_vm.vcadTemplateList,"show-select":false,"item-key":"name","loading":"true","dense":"","disable-pagination":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.label || item.name)+" ")]}},{key:"item.version",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"elevation-3 mr-1 ml-1 primary",attrs:{"label":"","small":""}},[_vm._v(" V "+_vm._s(_vm.printVersion(item))+" ")])]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"action"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","disabled":_vm.isDownloading},on:{"click":function($event){return _vm.downloadTemplate(item)}}},on),[_c('v-icon',[_vm._v("mdi-cloud-download")])],1)]}}],null,true)},[_c('span',[_vm._v("Download template")])]),(item.isPdfPresent)?[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","disabled":_vm.isDownloading,"color":"primary"},on:{"click":function($event){return _vm.downloadHelp(item)}}},on),[_c('v-icon',[_vm._v("mdi-file-pdf-outline")])],1)]}}],null,true)},[_c('span',[_vm._v("Download help file")])])]:_vm._e()],2)]}}],null,true)}),(_vm.authModeOn == true)?_c('v-text-field',{staticClass:"mt-10",attrs:{"append-icon":_vm.showKey ? 'mdi-eye' : 'mdi-eye-off',"append-outer-icon":"mdi-content-copy","type":_vm.showKey ? 'text' : 'password',"name":"txtFileKey","label":"API Key","readonly":""},on:{"click:append-outer":_vm.copyText,"click:append":function($event){_vm.showKey = !_vm.showKey}},model:{value:(_vm.fileKey),callback:function ($$v) {_vm.fileKey=$$v},expression:"fileKey"}}):_vm._e(),(_vm.isDownloading)?_c('v-progress-linear',{attrs:{"indeterminate":""}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }