import { render, staticRenderFns } from "./ProfileInfo.vue?vue&type=template&id=30a57e6a&scoped=true&"
import script from "./ProfileInfo.vue?vue&type=script&lang=js&"
export * from "./ProfileInfo.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "30a57e6a",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
installComponents(component, {VCardActions,VCardSubtitle,VCardTitle,VChip,VDivider,VIcon,VListItemAvatar})
