import axios from 'axios';
import store from '../store';

export function CheckOrCreateUser(hubId){
    let apiSettings = JSON.parse(JSON.stringify(store.getters.axiosSettings));
    apiSettings.params = { hubId: hubId };
    return axios.get('api/forge/user/getorcreateuser', apiSettings).then(ret => {
        return ret.data;
    })
}
