<template>
  <div style="height: 100%">
    <!-- APP MENU LIST -->
    <div class="d-inline-flex align-end app-menu-wrapper">
      <v-btn
        v-for="(menuItem, index) in visibleMenuItems"
        :key="'MENU_' + index"
        text
        tile
        class="vcad-menu-btn"
        :class="{
          'active-menu': menuItem.dialogData.targetPath == $route.path,
        }"
        @click="setAndShowDialog({ ...menuItem.dialogData, index: index })"
        :disabled="menuItem.dialogData.targetPath == $route.path"
        >{{ menuItem.btnData.text }}</v-btn
      >
    </div>

    <!-- DIALOG -->
    <vcad-dialog
      v-model="showDialog"
      :title="dialogTitle"
      :contentProps="dialogContentData"
    >
      <template v-slot:content="{ contentData }">
        <p>Are you sure you wish to switch to {{ contentData.targetView }}?</p>
      </template>
      <template v-slot:actions>
        <div
          class="d-inline-flex justify-end"
          style="width: 100%; height: 100%"
        >
          <v-btn
            outlined
            small
            color=""
            class="vcad-btn"
            @click="showDialog = false"
            >CLOSE</v-btn
          >
          <v-btn
            outlined
            small
            color="primary"
            class="vcad-btn"
            link
            :to="targetPath"
            @click="showDialog = false"
            >CONFIRM</v-btn
          >
        </div>
      </template>
    </vcad-dialog>
  </div>
</template>

<script>
import VcadDialog from "./VcadDialog";
import { mapGetters } from "vuex";
export default {
  components: {
    "vcad-dialog": VcadDialog,
  },
  data() {
    return {
      showDialog: false,
      dialogTitle: "",
      dialogContentData: {},
      targetPath: "",
      menuItems: [],
    };
  },

  computed: {
    ...mapGetters(["trialMode", "hasSLotManager", "vcadUserInfo"]),
    enableMultiModel() {
      try {
        return this.getConfigurationUserVcad.enableFederation;
      } catch (error) {
        return false;
      }
    },
    visibleMenuItems() {
      // General routes
      const genRoutes = [
        {
          btnData: {
            text: "DOCS",
          },
          dialogData: {
            title: "Switch to Document Manager",
            targetView: "Document Manager",
            targetPath: "/b360-doc-manager",
          },
        },
        {
          btnData: {
            text: "SLOTS",
          },
          dialogData: {
            title: "Switch to Slot Manager",
            targetView: "Slot Manager",
            targetPath: "/slot-manager",
          },
        },
      ];

      // Extra routes
      const extraRoutes = [];

        extraRoutes.push(
          {
          btnData: {
            text: "FEDERATIONS",
          },
          dialogData: {
            title: "Switch to Federation Management",
            targetView: "Federation Management",
            targetPath: "/federation-management",
          },
        });
      
      return [...genRoutes, ...extraRoutes];
    },
    getConfigurationUserVcad() {
      try {
        return JSON.parse(this.vcadUserInfo);
      } catch (error) {
        return {};
      }
    },
    enableProfilesVcad() {
      try {
        return this.getConfigurationUserVcad.profilesEnable;
      } catch (error) {
        return null;
      }
    },
  },
  methods: {
    setAndShowDialog({ title, targetView, targetPath }) {
      this.dialogTitle = title;

      const newObj = {
        targetView: targetView,
      };
      this.dialogContentData = Object.assign({}, newObj);

      this.targetPath = targetPath;

      this.showDialog = true;
    },
  },
  mounted() {},
};
</script>
<style lang="css" scoped>
.app-menu-wrapper {
  height: 100%;
  padding: 0.4em 0;
}
.vcad-menu-btn {
  height: 100% !important;
}
.theme--dark.active-menu {
  border-bottom: solid 2px white;
  color: white !important;
}
</style>