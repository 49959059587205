<template>
  <v-tooltip top :disabled="status.statusId != 3" color="grey darken-4">
    <template v-slot:activator="{ on }">
      <div
        id="status-wrapper"
        class="d-inline-flex justify-space-between align-center"
        v-on="on"
      >
        <span
          ><v-icon :color="status.color">{{ status.ico }}</v-icon></span
        >
        <span id="status-label" class="font-weight-bold ml-1">{{
          status.txt
        }}</span>
        <v-spacer></v-spacer>
        <v-divider vertical class="mx-1"></v-divider>
        <v-menu
          bottom
          min-width="150px"
          rounded
          offset-y
          close-on-click
          close-on-content-click
          v-model="menuModel"
        >
          <template v-slot:activator="{ on }">
            <v-btn icon v-on="on" :disabled="actionDisable" @click="setFile">
              <v-icon small>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
          <v-list dense>
            <!-- PREVIEW -->
            <v-list-item class="version-action-item">
              <v-list-item-content
                class="version-action-item-content justify-center"
              >
                <v-btn
                  outlined
                  small
                  color="primary"
                  class="vcad-btn file-action-btn"
                  @click="
                    setShowPreview({ show: true, mode: 0 });
                    closeMenu();
                  "
                  >View</v-btn
                >
              </v-list-item-content>
            </v-list-item>

            <!-- IMPORT -->
            <v-list-item
              class="version-action-item"
              v-if="status.statusId == -1"
            >
              <v-list-item-content
                class="version-action-item-content justify-center"
              >
                <vcad-import-dialog
                  v-model="importDialog"
                  :importFn="vcadFileImport"
                  :fileName="item.fileName"
                  :disableActivator="importingFile"
                  :importingFile="importingFile"
                  :filter="item.filterId ? item.filterId : null"
                ></vcad-import-dialog>
              </v-list-item-content>
            </v-list-item>

            <!-- SLOT RETARGET -->
            <v-list-item
              class="version-action-item"
              v-if="status.statusId == 2"
            >
              <v-list-item-content
                class="version-action-item-content justify-center"
              >
                <slot-version-changer
                  :slotGuid="slotChangerInfo.slotGuid"
                  :fileId="slotChangerInfo.fileId"
                  :fileType="slotChangerInfo.fileType"
                  :projectId="slotChangerInfo.projectId"
                ></slot-version-changer>
              </v-list-item-content>
            </v-list-item>

            <!-- REMOVE -->
            <v-list-item
              class="version-action-item"
              v-if="status.statusId == 2 || status.statusId == 3"
            >
              <v-list-item-content
                class="version-action-item-content justify-center"
              >
                <vcad-dialog
                  v-model="removeSlotDialog"
                  title="Clear slot"
                  :contentProps="{ fileName: item.fileName }"
                >
                  <template v-slot:dialogActivator="{ on }">
                    <v-btn
                      outlined
                      v-on="on"
                      small
                      color="error darken-1"
                      class="vcad-btn file-action-btn"
                      @click="
                        closeMenu();
                        removeSlotDialog = true;
                      "
                      >Remove</v-btn
                    >
                  </template>

                  <template v-slot:content="{ contentData }">
                    <p class="text-center">
                      You are about to clear the selected slot from the current
                      loaded model :
                      <i
                        ><b>{{ contentData.fileName }}</b></i
                      >
                    </p>
                    <v-alert border="left" outlined text type="warning" dense>
                      <p class="text-center">
                        Previously generated reports may not work correctly. Are
                        you sure you wish to proceed?
                      </p>
                    </v-alert>
                  </template>

                  <!-- ACTIONS -->
                  <template v-slot:actions>
                    <div
                      class="d-inline-flex justify-end"
                      style="width: 100%; height: 100%"
                    >
                      <v-btn
                        outlined
                        small
                        color=""
                        class="vcad-btn"
                        @click="removeSlotDialog = false"
                        >CLOSE</v-btn
                      >
                      <v-btn
                        outlined
                        small
                        color="primary"
                        class="vcad-btn"
                        @click="removeVcadFile"
                        >CONFIRM</v-btn
                      >
                    </div>
                  </template>
                </vcad-dialog>
              </v-list-item-content>
            </v-list-item>

            <!-- TEMPLATE SELECTION -->
            <v-list-item
              class="version-action-item"
              v-if="status.statusId == 2"
            >
              <v-list-item-content
                class="version-action-item-content justify-center"
              >
                <v-btn
                  outlined
                  small
                  color="primary"
                  class="vcad-btn file-action-btn"
                  @click.stop="
                    openTemplateSelector();
                    closeMenu();
                  "
                >
                  Templates
                </v-btn>
              </v-list-item-content>
            </v-list-item>

            <!-- RESET STATUS -->
            <v-list-item
              class="version-action-item"
              v-if="status.statusId == 3 || status.statusId == 2"
            >
              <v-list-item-content
                class="version-action-item-content justify-center"
              >
                <vcad-dialog
                  v-model="resetStatusDialog"
                  title="Reset status"
                  :contentProps="{ fileName: item.displayName }"
                >
                  <template v-slot:dialogActivator="{ on }">
                    <v-btn
                      v-on="on"
                      outlined
                      small
                      color="primary"
                      class="vcad-btn file-action-btn"
                      @click="resetStatusDialog = false"
                      >Restart conversion</v-btn
                    >
                  </template>

                  <template v-slot:content="{ contentData }">
                    <!-- FILTER -->
                    <div
                      class="body-1 d-flex align-center justify-space-between"
                      v-if="enableProfilesVcad"
                    >
                      <div class="d-flex">
                        <vcad-filter
                          :toApply="true"
                          v-model="filterApply"
                          :fileType="contentData.fileName.split('.').pop()"
                          @closeFilterDialog="closeFilterDialog"
                          @onSupportedTypesLoad="onSupportedTypesLoad"
                        >
                          <template v-slot:filterDialogActivator="{ on }">
                            <div class="d-flex align-center">
                              <v-btn
                                outlined
                                small
                                color="primary"
                                class="vcad-btn"
                                v-on="on"
                                :disabled="!allowedTypeCheck"
                              >
                                <v-icon class="">mdi-filter</v-icon>Add
                                filter</v-btn
                              >
                              <div class="ml-2" v-if="!allowedTypeCheck">
                                Filters are not available for the current file type
                              </div>
                            </div>
                          </template>
                        </vcad-filter>
                      </div>
                      <div class="d-flex align-center">
                        <b v-if="allowedTypeCheck">
                          {{
                            !filterSet
                              ? "No filter applied"
                              : `Filter applied: ${filterApply.profileName}`
                          }}
                        </b>
                        <!-- <v-icon
                            v-if="filterSet"
                            small
                            class="ml-1"
                            color="success"
                            >mdi-check-bold</v-icon
                          > -->
                        <v-btn
                          class=""
                          v-if="filterSet"
                          icon
                          @click="filterApply = null"
                        >
                          <v-icon>mdi-close</v-icon>
                        </v-btn>
                      </div>
                    </div>
                    <v-divider class="my-4"></v-divider>

                    <p class="text-center">
                      You are about to restart the file conversione process.
                      This operation can be useful if there has been some import
                      error.
                    </p>
                    <v-alert border="left" outlined text type="info" dense>
                      <p class="text-center">
                        The slot used by this file will not change so the
                        existing report will continue to work after a new
                        import.
                      </p>
                    </v-alert>
                  </template>

                  <!-- ACTIONS -->
                  <template v-slot:actions>
                    <div
                      class="d-inline-flex justify-end"
                      style="width: 100%; height: 100%"
                    >
                      <v-btn
                        outlined
                        small
                        color=""
                        class="vcad-btn"
                        @click="resetStatusDialog = false"
                        >CLOSE</v-btn
                      >
                      <v-btn
                        outlined
                        small
                        color="primary"
                        class="vcad-btn"
                        @click="resetVcadFileStatus"
                        >CONFIRM</v-btn
                      >
                    </div>
                  </template>
                </vcad-dialog>
              </v-list-item-content>
            </v-list-item>

            <!-- VIEWS LIST -->
            <v-list-item class="version-action-item" v-if="viewsFileCondition">
              <v-list-item-content
                class="version-action-item-content justify-center"
              >
                <vcad-views-list-dialog></vcad-views-list-dialog>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </template>
    <span>More info in the "Additional info" section.</span>
  </v-tooltip>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { getFileStatus, vcadFileStatusDict } from "../utils/VCadItemStatusUtil";
import VcadDialog from "./VcadDialog";
import VcadImportDialog from "./VcadImportDialog";
import SlotVersionChanger from "./SlotVersionChanger";
import VcadViewsListDialog from "./VcadViewsListDialog";
import VcadFilter from "../components/filters/FilterProfileDialog.vue";

export default {
  props: {
    item: {
      required: true,
      // default: null,
    },
    /**
     * Determines if the actions should be disabled
     */
    disabledActions: {
      type: Boolean,
      required: false,
      default: false,
    },
    /**
     * Used by SlotVersionChanger
     */
    relativeFileId: {
      type: String,
      required: true,
    },
    relativeFileType: {
      type: String,
      required: true,
    },
    relativeProjectId: {
      type: String,
      required: true,
    },
  },
  components: {
    "vcad-import-dialog": VcadImportDialog,
    "vcad-dialog": VcadDialog,
    "slot-version-changer": SlotVersionChanger,
    "vcad-views-list-dialog": VcadViewsListDialog,
    "vcad-filter": VcadFilter,
  },
  data() {
    return {
      statusDict: {},
      removeSlotDialog: false,
      resetStatusDialog: false,
      importDialog: false,
      importingFile: false,
      menuModel: false,
      viewsListDialog: false,
      filterApply: null,
      filterDialog: false,
      allowedTypeCheck: false,
    };
  },
  watch: {
    status: {
      deep: true,
      handler(status) {
        if (status.statusId == 0) {
          this.importingFile = false;
        }
        if (status.statusId == 1) {
          this.importingFile = true;
        }
      },
    },
  },
  computed: {
    ...mapGetters([
      "vcadFileList",
      "userProfile",
      "supportedVcadFileTypes",
      "vcadUserInfo",
    ]),
    getConfigurationUserVcad() {
      try {
        return JSON.parse(this.vcadUserInfo);
      } catch (error) {
        return null;
      }
    },
    enableProfilesVcad() {
      try {
        return this.getConfigurationUserVcad.profilesEnable;
      } catch (error) {
        return null;
      }
    },
    filterSet() {
      if (this.filterApply) {
        return true;
      } else {
        return false;
      }
    },
    status() {
      let vcadStatus = getFileStatus(
        this.vcadFileList,
        this.item,
        this.supportedVcadFileTypes
      );
      const stat = vcadFileStatusDict[vcadStatus];
      return stat;
    },
    viewsFileCondition() {
      return this.item.fileType == "rvt";
    },
    actionDisable() {
      return this.disabledActions || this.status.statusId == -2;
    },
    /**
     * Information required by the Slot Changer component.
     */
    slotChangerInfo() {
      let ret = {};
      const projectUID = this.relativeProjectId.split("/").pop();

      // Check if current file is an item or a version
      if (this.item.type == "versions") {
        ret.fileId = this.item.sourceItemIdForVersion;
        ret.fileType = this.item.versionType;
      } else {
        ret.fileId = this.item.itemId;
        ret.fileType = this.item.type;
      }

      ret.slotGuid = this.item.vcadItem.id;
      ret.projectId = projectUID;
      ret.currentVersionLabel = `V${this.item.revisionDisplayLabel} (${this.item.lastUpdateDate})`;

      return ret;
    },
  },
  methods: {
    ...mapActions([
      "setShowPreview",
      "setShowTemplates",
      "setVcadImport",
      "restartConversion",
      "deleteVcadFile",
      "setCurrentViews",
      "setVcadTemplateList",
      "setFileData",
      "getManifestGraphicsResourceFromGeometry",
      "setSlotStatus",
    ]),
    onSupportedTypesLoad(supportedTypes) {
      this.allowedTypeCheck = supportedTypes.length > 0;
    },
    closeFilterDialog(value) {
      this.filterDialog = value;
    },
    /**
     * Import the file to Vcad.
     */
    vcadFileImport(filterId) {
      // If the file is being imported, then skip.

      if (this.importingFile) {
        return true;
      }

      // Close menu list
      this.closeMenu();

      this.importingFile = true;
      let email = this.userProfile.email;

      let info = {
        userId: this.userProfile.id,
        email: email,
        itemId: this.item.itemId,
        fileName: this.item.fileName,
        viewableId: this.item.viewableId,
        viewableGuid: this.item.viewableGuid,
        viewableName: this.item.viewableName,
        b360Type: this.item.type,
        b360Item: this.item,
      };

      let payload = {
        email: email,
        fileName: this.item.fileName,
        urn: this.item.derivativeUrn,
        viewableGuid: this.item.viewableGuid,
        viewableName: this.item.viewableName,
        info: info,
        filterId: filterId,
      };

      this.setVcadImport(payload)
        .then(() => {
          this.importingFile = false;
        })
        .catch(() => {
          this.importingFile = false;
          // console.log(err);
        });
    },
    /**
     * Clear vcad slot.
     */
    removeVcadFile() {
      this.deleteVcadFile({ guid: this.item.vcadItem.id })
        .then(() => {
          this.removeSlotDialog = false;
        })
        .catch((err) => {
          console.error(err);
        });
    },

    /**
     * Reset file import status, keeping link to current slot.
     */
    resetVcadFileStatus() {
      this.restartConversion({
        slotGuid: this.item.vcadItem.id,
        filterId: this.filterApply?.profileId,
      }).finally(() => {
        this.resetStatusDialog = false;
      });

      // this.setSlotStatus({
      //   slotGuid: this.item.vcadItem.id,
      //   status: 0,
      // }).finally(() => {
      //   this.resetStatusDialog = false;
      // });
    },
    /**
     * Setup current views and open template dialog
     */
    async openTemplateSelector() {
      // Load and set current views
      await this.setCurrentViews(this.item);

      // Set template list
      await this.setVcadTemplateList(this.item.vcadItem.type);

      // Open template selector component
      this.setShowTemplates(true);
    },

    /**
     * Set current forge file for use by "preview" and "template".
     * The function also emits an "selectedStatus" event.
     * @returns {Promise} promis of the store action.
     */
    setFile() {
      return this.setFileData({ slot: 1, data: this.item }).then((res) => {
        this.$emit("selected-status");
        return res;
      });
    },
    /**
     * Close menu when performing actions
     */
    closeMenu() {
      this.menuModel = false;
    },
  },
};
</script>

<style lang="css" scoped>
#status-label {
  font-size: 0.75rem;
}
#status-wrapper {
  /* border: solid 1px black; */
  /* border-radius: 25px; */
  border-radius: 7px;
  padding: 0 0 0 5px;
  border: solid 2px rgba(0, 0, 0, 0.12);
  width: 100%;
}
#version-action-list {
  background-color: #ffffff;
}
.version-action-item {
  min-height: 30px !important;
}
.version-action-item-content {
  padding-top: 2px !important;
  padding-bottom: 2px !important;
}
</style>