<template>
    <vcad-dialog
        v-model="showDialog"
        title="Change model version"
        dialogWidth="650"
    >
        <template v-slot:dialogActivator="{ on }">
            <v-btn
                v-on="on"
                outlined
                small
                color="primary"
                class="vcad-btn file-action-btn"
            >UPD SLOT</v-btn>
        </template>

        <template v-slot:content>
            <v-row>
                <v-col :cols="12">                    
                    <p class="text-body-1">Change the model version used by the selected slot.</p>
                    <v-alert
                        border="left"
                        outlined
                        text
                        type="warning"
                        dense
                    ><b>NOTE:</b> By changing the active model verison, the reports creted using this slot will display the information coming from the new model version.</v-alert>
                </v-col>
            </v-row>
            <v-row v-if="versionItems.length > 0 || loadingVersions">
                <v-col :cols="5" class="d-flex align-center justify-center">
                    <v-text-field
                        label="Current Version"
                        readonly
                        v-model="currentVersionDescr"
                        outlined
                        dense
                        hint="Version being used by the current slot"
                        persistent-hint
                    ></v-text-field>
                </v-col>
                <v-col :cols="1" class="d-flex align-center justify-center"><v-icon>mdi-arrow-right</v-icon></v-col>
                <v-col :cols="6" class="d-flex align-end justify-center">
                    <v-select
                        v-model="selectedVersion"
                        label="Target version"
                        :items="versionItems"
                        item-text="tmpLabel"
                        item-value="id"
                        outlined
                        dense
                        :loading="loadingVersions"
                        clearable
                        hint="New version to use with the current slot"
                        persistent-hint
                        return-object
                    ></v-select>
                </v-col>
            </v-row>
            <v-row v-else>
                <v-col :cols="12">
                    <p class="text-center">Sorry, there are no valid version to switch to...</p>
                </v-col>
            </v-row>
        </template>

        <template v-slot:actions>
            <div class="d-inline-flex justify-end" style="width:100%; height:100%;">
                <v-btn
                    outlined
                    small
                    color=""
                    class="vcad-btn"
                    @click="showDialog = false"
                >CLOSE</v-btn>
                <v-btn
                    :disabled="!selectedVersion"
                    outlined
                    small
                    color="primary"
                    class="vcad-btn"
                    @click="updateModelInSlot"
                >SET VERSION</v-btn>
            </div>
        </template>
        
    </vcad-dialog>
</template>

<script>
    import { mapActions, mapGetters } from "vuex";
    import VcadDialog from "./VcadDialog";
    import {getFileStatus} from "../utils/VCadItemStatusUtil";
    
    export default {
        props : {
            slotGuid : {
                type: String,
                required: true
            },
            fileId: {
                type: String,
                required: true
            },
            fileType: {
                type: String,
                required: true
            },
            projectId: {
                type: String,
                required: true
            },
            currentVersionLabel: {
                type: String,
                required: false,
                default: null
            },
        },
        components: {
            "vcad-dialog" : VcadDialog
        },
        data(){
            return {
                showDialog: false,
                versionItems: [],
                selectedVersion: null,
                loadingVersions: false,
            }
        },
        computed:{
            ...mapGetters([
                "userProfile",
                "vcadFileList"
            ]),
            currentVersionDescr(){
                return this.currentVersionLabel ? this.currentVersionLabel : 'Current version';
            }
        },
        methods:{
            ...mapActions([
                'setVcadImport',
                'getVersions',
                'retrieveDerivativeUrnFromDocVersion'
            ]),
            /**
             * Load list of versions for the given file.
             */
            loadVersions(){
                this.loadingVersions = true;

                const itemData = {
                    id: this.fileId,
                    type: this.fileType,
                    projectId: this.projectId
                };
                const payload = {
                    item: itemData,
                    addChildrenToParent: false
                };

                let th = this;
                
                this.getVersions(payload)
                // Load Derivative urn for bim360documents versions
                .then( async (items) => {
                    items = items || [];

                    let promiseList = [];
                    items.forEach((item) => {
                        if (items[0].versionType == "bim360documents") {
                            let derivativeUrnPromise = this.retrieveDerivativeUrnFromDocVersion(item)
                            .then((val) => {
                                item.derivativeUrn = val;
                            });
                            promiseList.push(derivativeUrnPromise);
                        }
                    });

                    await Promise.all(promiseList);
                    return items;
                })
                // Filter and alter version objects
                .then( (items) => {
                    th.versionItems = items
                    .filter( versionObj => {
                        let vcadStatus = getFileStatus(th.vcadFileList ,versionObj);
                        return vcadStatus != 2;                                         // Exclude versions already in use
                    })
                    .map( versionObj => {
                        let tmp = Object.assign({}, versionObj);
                        tmp.tmpLabel =`V${versionObj.revisionDisplayLabel} (${versionObj.lastUpdateDate})`
                        
                        return tmp;
                    });
                    th.loadingVersions = false;
                })
                .catch( err => {
                    console.log(err);
                });
            },
            /**
             * Update model in slot.
             */
            updateModelInSlot(){
                let tmp = Object.assign({}, this.selectedVersion);
                delete tmp.tmpLabel;

                let info = {
                    userId: this.userProfile.id,
                    email: this.userProfile.email,
                    itemId: tmp.itemId,
                    fileName: tmp.fileName,
                    viewableGuid: tmp.viewableGuid,
                    viewableName: tmp.viewableName,
                    b360Item: tmp
                };
                let payload = {
                    email: this.userProfile.email,
                    fileName: tmp.fileName,
                    urn: tmp.derivativeUrn,  
                    viewableGuid: tmp.viewableGuid,
                    viewableName: tmp.viewableName,
                    info: info,
                    updateSlotId: this.slotGuid             // Slot to update to new version
                };

                this.setVcadImport(payload)
                .then( () => {
                    this.showDialog = false;
                })
                .catch( (err) => {
                    console.log(err);
                });

            }
        },
        mounted(){
            this.loadVersions();
        }
    }
</script>

<style lang="css" scoped>
</style>