import VcadTemplatePanel from "./VcadTemplatePanel";
import axios from "axios";
export default function (AutodeskViewing) {
    //eslint-disable-next-line
  return class ToolbarExtension extends AutodeskViewing.Extension {
      viewer;
      options;
      subToolbar;
      panel;
      constructor(viewer, options) {
          super(viewer, options);
          this.viewer = viewer;
          this.options = options;
     //eslint-disable-next-line
         
        }

      load = function () {
          if (this.viewer.toolbar) {
              // Toolbar is already available, create the UI
             //// console.log("toolbar_createui");
              this.createUI();
          } else {
              // Toolbar hasn't been created yet, wait until we get notification of its creation
              this.onToolbarCreatedBinded = this.onToolbarCreated.bind(this);
              this.viewer.addEventListener(AutodeskViewing.TOOLBAR_CREATED_EVENT, this.onToolbarCreatedBinded);
          }

          return true;
      }

      unload = function () {
          this.viewer.toolbar.removeControl(this.subToolbar);
          return true;
      };

      onToolbarCreated = function () {
          this.viewer.removeEventListener(AutodeskViewing.TOOLBAR_CREATED_EVENT, this.onToolbarCreatedBinded);
          this.onToolbarCreatedBinded = null;
          this.createUI();
      };

      createUI = async function () {

       // console.log("createUI");

          let viewer = this.viewer;
          let panel = this.panel;
let th = this;


         // const vC = await viewer.loadExtension('Autodesk.ViewCubeUi');

          // Button 1
            //eslint-disable-next-line
          let button1 = new AutodeskViewing.UI.Button('my-view-front-button');
          button1.onClick = function () {
              

            if (th.options.compareMode == false)
            {
              if (panel == null) {
                panel = new VcadTemplatePanel(viewer, { id:'vcadTemplatePanel', title:'Template List'} 
                   );
            }

            panel.setVisible(!panel.isVisible());
            }
          else
          {
           
           
            axios.get("https://bimapi.v-cad.it/File/GetFile?guidFile=reportDiff.pbit&type=staticfile",{ responseType:"blob"}).then((data) => {
              var fileURL = window.URL.createObjectURL(
                new Blob([data.data], { type: "application/x-zip-compressed" })
              );
              var fileLink = document.createElement("a");
              fileLink.href = fileURL;
              fileLink.setAttribute(
                "download",
                "reportDiff.pbit"
              );
              document.body.appendChild(fileLink);
              fileLink.click();
            });


          
          }



          };
          button1.addClass('my-view-front-button');
          button1.setToolTip('Vcad Templates');
          button1.setIcon('adsk-icon-box'); 

         

          // SubToolbar
            //eslint-disable-next-line
          this.subToolbar = new AutodeskViewing.UI.ControlGroup('my-custom-view-toolbar');
          this.subToolbar.addControl(button1);
    
          viewer.toolbar.addControl(this.subToolbar);

         
          
        
        //instance.$mount() ;



      };


      
  }

}



