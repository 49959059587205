<template>
  <v-menu bottom min-width="300px" rounded offset-y>
    <template v-slot:activator="{ on }">
      <v-btn icon x-large v-on="on">
        <v-avatar color="secondary darken-1" size="51">
          <v-avatar color="secondary" size="45">
            <span style="color: white">{{ userProfile.initials }}</span>
          </v-avatar>
        </v-avatar>
      </v-btn>
    </template>
    <v-list>
      <div class="d-flex align-center" style="cursor: pointer;" @click.stop="showProfileInfoDialog = true;">
        <v-list-item-avatar>
          <img :src="userProfile.img" alt="" />
        </v-list-item-avatar>
        <h3>{{ userProfile.name }}</h3>
      </div>
      <!-- <p class="caption mt-1">
            <v-icon small>mdi-email</v-icon> {{ userProfile.email }}
          </p> -->
      <v-divider class="my-3"></v-divider>
      <v-list flat>
          <v-list-item
            v-for="(setting, key) in settings"
            @click.stop="buttonsFunc(setting)"
            :key="key"
          >
            <v-list-item-icon>
              <v-icon>{{ setting.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ setting.text }}</v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
              <v-icon color="grey lighten-1">mdi-menu-right</v-icon>
            </v-list-item-action>
          </v-list-item>
          <!-- <v-list-item @click="$emit('sign-out')" depressed text>
            <v-icon class="mr-3">mdi-logout</v-icon>Sign Out</v-list-item
          > -->
      </v-list>
    </v-list>
    <vcad-configuration
      v-model="showConfigurationDialog"
      title="Settings"
      dialogWidth="1000"
    >
    </vcad-configuration>
    <profile-info
      v-model="showProfileInfoDialog"
      title="Settings"
      dialogWidth="900"
    ></profile-info>
  </v-menu>
</template>

<script>
import { mapGetters } from "vuex";
import ProfileInfo from "./ProfileInfo.vue";
import VcadConfiguration from "./VcadConfiguration.vue";

export default {
  components: {
    VcadConfiguration,
    ProfileInfo,
  },
  computed: {
    ...mapGetters(["isLogged", "slotUsedCount", "userProfile", "slotCount"]),
  },
  data() {
    return {
      showProfileInfoDialog: false,
      showConfigurationDialog: false,
      settings: [
        // { text: "My profile", icon: "mdi-account" },
        { text: "Profile info", icon: "mdi-account" },
        { text: "Settings", icon: "mdi-cog" },
        { text: "Sign Out", icon: "mdi-logout" },
      ],
    };
  },
  methods: {
    buttonsFunc(setting) {
      switch (setting.text) {
        // case "My profile": {
        //   console.log("aeaa");
        //   break;
        // }

        case "Sign Out": {
          this.$emit("sign-out");
          break;
        }

        case "Profile info": {
          // const routeData = this.$router.resolve({ name: "ProfileInfo" });
          // window.open(routeData.href, '_blank');
          this.showProfileInfoDialog = true;
          break;
        }

        case "Settings": {
          this.showConfigurationDialog = true;
          break;
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.v-list-item:hover {
  background: rgba(0, 0, 0, 0.034);
}
</style>