import axios from "axios";
import {
    getFileStatus
} from "../utils/VCadItemStatusUtil";
import {
    CheckOrCreateUser
} from "../utils/CheckOrCreateUser";
import router from '../router/index';
const namespaced = false;

const state = {
    trialMode: -1,
    expirationDate: null,
    subscriptionStatus: null,
    accountKey: null,
    slotCount: -1,
    slotUsedCount: 0,
    sensorData: null,
    vCadFileStatus: -1,
    userProfile: null,
    isLoading: true,
    hubList: [],
    currentHub: null,
    projects: [],
    selectedProject: null,
    selectedFolder:null,
    showPreview: false,
    resizePreview: false,
    previewMode: 0,
    showTemplates: false,
    forgeToken: null,
    vcadToken: null,
    vcadTemplateList: [],
    vcadFileList: [],
    currentViews: [],
    currentForgeFileData: {
        itemId: null,
        urn: null,
        viewableId: null,
        viewableGuid: null,
        viewableName: null,
        fileName: null,
        type: null,
        b360Item: null,
        vcadItem: null
    },
    currentForgeFileData2: {
        itemId: null,
        urn: null,
        viewableId: null,
        viewableGuid: null,
        viewableName: null,
        fileName: null,
        type: null,
        b360Item: null,
        vcadItem: null
    },
    documentTypeName: "bim360documents",
    itemTypeName: "items",
    versionTypeName: "versions",
    accountId: null,
    axiosErrorState: {
        state: 0,
        errorNr: 0,
        message: "",
        route: "",
        isBlocking: ""
    },
    supportedVcadFileTypes: [],
    vcadUserInfo: null,
    snackbarInfo: {
        isVisible: false,
        text: null,
        color: null,
        timeoutTime: null,
        onCloseFunc: null
    }
};

const getters = {
    trialMode: state => {
        return state.trialMode;
    },
    expirationDate: state => {
        return state.expirationDate;
    },
    subscriptionStatus: state => {
        return state.subscriptionStatus;
    },
    accountKey: state => {
        return state.accountKey;
    },
    axiosErrorState: state => {
        return state.axiosErrorState;
    },
    slotCount: state => {
        return state.slotCount;
    },
    slotUsedCount: state => {
        return state.slotUsedCount;
    },
    accountId: state => {
        return state.accountId;
    },
    axiosSettings: state => {
        let settings = {
            baseURL: state.baseURL, // process.env.VUE_APP_API,
            withCredentials: true
        }

        if (state.accountKey) {
            settings["headers"] = {
                "x-vcad-bim360-hubid": state.accountKey
            }
        }
        return settings;
    },
    currentViews: state => {
        return state.currentViews;
    },
    isLoading: state => {
        return state.isLoading;
    },
    sensorData: state => {
        return state.sensorData;
    },
    previewMode: state => {
        return state.previewMode;
    },
    resizePreview: state => {
        return state.resizePreview;
    },
    documentTypeName: state => {
        return state.documentTypeName;
    },
    itemTypeName: state => {
        return state.itemTypeName;
    },
    versionTypeName: state => {
        return state.versionTypeName;
    },
    userProfile: state => {
        return state.userProfile;
    },
    vCadFileStatus: state => {
        return state.vCadFileStatus;
    },
    currentForgeFileData: state => {
        return state.currentForgeFileData;
    },
    currentForgeFileData2: state => {
        return state.currentForgeFileData2;
    },
    vcadTemplateList: state => {
        return state.vcadTemplateList;
    },
    showTemplates: state => {
        return state.showTemplates;
    },
    showPreview: state => {
        return state.showPreview;
    },
    forgeToken: state => {
        return state.forgeToken;
    },
    vcadFileList: state => {
        return state.vcadFileList;
    },
    currentHub: state => {
        return state.currentHub;
    },
    hubList: state => {
        return state.hubList;
    },
    projects: state => {
        return state.projects;
    },
    selectedProject: state => {
        return state.selectedProject;
    },

    selectedFolder: state => {
        return state.selectedFolder;
    },

    supportedVcadFileTypes: state => {
        return state.supportedVcadFileTypes
    },
    signalRbaseURL: s => {
        return s.signalRbaseURL;
    },
    snackbarInfo: state => {
        return state.snackbarInfo;
    },
    snackbarIsVisible: state => {
        return state.snackbarInfo.isVisible;
    },
    vcadUserInfo: state => {
        return state.vcadUserInfo;
    }
};

const mutations = {
    setSensorData(state, payload) {
        state.sensorData = payload;
    },
    setProjects(state, payload) {
        state.projects = payload;
    },

    SET_ACCOUNT_KEY(state, payload) {
        state.accountKey = payload;
    },

    SET_TRIAL_MODE(state, payload) {
        state.trialMode = payload;
    },
    SET_EXPIRATION_DATE(state, payload) {
        state.expirationDate = payload;
    },
    SET_SUBSCRIPTION_STATUS(state, payload) {
        state.subscriptionStatus = payload;
    },
    SET_SLOT_COUNT(state, payload) {
        state.slotCount = payload;
    },
    SET_SLOT_USED_COUNT(state, payload) {
        state.slotUsedCount = payload;
    },
    SET_ACCOUNT_ID(state, payload) {
        state.accountId = payload;
    },
    SET_IS_LOADING(state, payload) {
        state.isLoading = payload;
    },
    SET_SELECTED_PROJECT(state, payload) {
        state.selectedProject = payload;
    },
    SET_SELECTED_FOLDER(state, payload) {
        state.selectedFolder = payload;
    },
    SET_CURRENT_HUB(state, payload) {
        state.currentHub = payload;
    },
    SET_HUB_LIST(state, payload) {
        state.hubList = payload;
    },
    SET_FORGE_TOKEN(state, payload) {
        state.forgeToken = payload;
    },
    SET_VCAD_FILE_LIST(state, payload) {
        state.vcadFileList = payload;
    },
    SET_SHOW_PREVIEW(state, payload) {
        state.showPreview = payload;
    },
    SET_PREVIEW_MODE(state, payload) {
        state.previewMode = payload;
    },
    SET_RESIZE_PREVIEW(state, payload) {
        state.resizePreview = payload;
    },
    SET_SHOW_TEMPLATES(state, payload) {
        state.showTemplates = payload;
    },
    SET_VCAD_TEMPLATE_LIST(state, payload) {
        state.vcadTemplateList = payload;
    },
    SET_CURRENT_FORGE_FILE_DATA(state, payload) {
        state.currentForgeFileData = payload;
    },
    SET_CURRENT_FORGE_FILE_DATA2(state, payload) {
        state.currentForgeFileData2 = payload;
    },
    SET_USER_PROFILE(state, payload) {
        state.userProfile = payload;
    },
    SET_CURRENT_VIEWS(state, payload) {
        state.currentViews = payload;
    },
    SET_VCAD_FILE_STATUS(state, payload) {
        state.vCadFileStatus = payload;
    },
    SET_BASE_URL(state, payload) {
        state.baseURL = payload;
    },
    SET_SIGNALR_BASE_URL(s, payload) {
        s.signalRbaseURL = payload;
    },
    SET_AXIOS_ERROR_STATE(state, payload) {
        state.axiosErrorState = payload;
    },
    SET_SUPPORTED_VCAD_FILE_TYPES(state, payload) {
        state.supportedVcadFileTypes = payload;
    },
    SET_SNACKBAR_INFO(state, payload) {
        state.snackbarInfo = payload;
    },
    SET_VCAD_USER_INFO(state, payload) {
        state.vcadUserInfo = payload;
    }
};

const actions = {
    /**
     * Clear the currentForgeFile.
     * @param {Object} context Object that exposes the same set of methods/properties on the store instance
     */
    clearFileData(context, slot) {
        // Clear the selected slot
        let emptyTemplate = {
            itemId: null,
            urn: null,
            viewableId: null,
            viewableGuid: null,
            viewableName: null,
            fileName: null,
            type: null,
            b360Item: null,
            vcadItem: null
        }
        if (slot == 1) {
            context.commit('SET_CURRENT_FORGE_FILE_DATA', emptyTemplate);
        }
        if (slot == 2) {
            context.commit('SET_CURRENT_FORGE_FILE_DATA2', emptyTemplate);
        }
    },
    /**
     * Set the currentForgeFile.
     * @param {Object} context Object that exposes the same set of methods/properties on the store instance
     * @param {Object} payload Object in the form "{ 'slot': <Number>, 'data': <Object> }"
     */
    async setFileData(context, payload) {
        // Clear the selected slot
        await context.dispatch('clearFileData', payload.slot);

        // Set the file data
        let data = payload.data;
        let tmpFileData = {}
        if (
            data.type == context.getters.documentTypeName ||
            data.type == context.getters.versionTypeName ||
            data.type == context.getters.itemTypeName
        ) {
            // Set vcadItem
            if (data.vcadItem) {
                tmpFileData.vcadItem = data.vcadItem;
            } else {
                let vcadFiles = context.getters.vcadFileList;
                // tmpFileData.vcadItem = vcadFiles.find( (item) => {
                //   return item.forgeUrn == data.derivativeUrn;
                // })
                tmpFileData.vcadItem = vcadFiles.find((vcadItem) => {
                    //Note:viewableId from vcad == viewableGuid from B360
                    if (vcadItem.forgeUrn == data.derivativeUrn) {
                        return !data.viewableGuid || data.viewableGuid == vcadItem.viewableId;
                    }
                });
            }
            // TODO: Implement date formatting for all dates in fielInfo
            // TODO: Create a function to use for formatting dates
            // Format dates
            if (tmpFileData.vcadItem) {
                let options = { dateStyle: 'medium', timeStyle: 'short', hour12: false };
                let tmpDateConv = new Date(tmpFileData.vcadItem.dateEndConv);
                tmpFileData.vcadItem.dateEndConv = tmpDateConv.toLocaleString(window.navigator.language, options);
            }

            // Set b360Item
            delete data.vcadItem;
            tmpFileData.b360Item = data;

            // Set other data
            tmpFileData.itemId = data.itemId;
            tmpFileData.fileName = data.fileName;

            if (data.id.indexOf("|") > -1) {
                tmpFileData.urn = data.id.split("|")[1];
                tmpFileData.viewableId = data.id.split("|")[2];
                tmpFileData.viewableGuid = data.viewableGuid;
                tmpFileData.viewableName = data.viewableName;
            } else {
                tmpFileData.urn = data.id;
            }
        }

        // Update current file for requested slot
        if (payload.slot == 1) {
            context.commit('SET_CURRENT_FORGE_FILE_DATA', Object.assign({}, tmpFileData));
        }
        if (payload.slot == 2) {
            context.commit('SET_CURRENT_FORGE_FILE_DATA2', Object.assign({}, tmpFileData));
        }

        // // Load and set currentViews
        // let itemTypeName = context.getters.itemTypeName();
        // let documentTypeName = context.getters.documentTypeName();

        // // Item type files
        // if(tmpFileData.b360Item.type == itemTypeName || tmpFileData.b360Item.versionType == itemTypeName){
        //   let derivativeUrn = tmpFileData.b360Item.derivativeUrn;
        //   context.dispatch('setCurrentViewsFromUrn', { urn: derivativeUrn })
        //   .then( (res) => {
        //     console.log('Just checking: ', res);
        //   })
        // }
        // // Document type files

        let currentFileStatus = getFileStatus(
            context.getters.vcadFileList,
            context.getters.currentForgeFileData.b360Item,
            context.getters.supportedVcadFileTypes
        );

        context.dispatch("setVcadFileStatus", currentFileStatus);

        return tmpFileData;
    },
    // setFileData({
    //   getters,
    // }, params) {

    //   let ffd = null;
    //   if (params.slot == 1) {
    //     ffd = getters.currentForgeFileData;
    //   }
    //   if (params.slot == 2) {
    //     ffd = getters.currentForgeFileData2;
    //   }


    //   let data = params.data;


    //   ffd.itemId = null;
    //   ffd.urn = null;
    //   ffd.viewableId = null,
    //     ffd.viewableGuid = null;
    //   ffd.viewableName = null;
    //   ffd.fileName = null,
    //     ffd.type = null;
    //   ffd.b360Item = null;
    //   ffd.vcadItem = null;


    //   if (
    //     data.type == getters.documentTypeName ||
    //     data.type == getters.versionTypeName ||
    //     data.type == getters.itemTypeName

    //   ) {
    //     ffd.b360Item = data;

    //     if (data.id.indexOf("|") > -1) {
    //       ffd.urn = data.id.split("|")[1];
    //       ffd.viewableId = data.id.split("|")[2];
    //       ffd.viewableGuid = data.viewableGuid;
    //       ffd.viewableName = data.viewableName;
    //     } else {
    //       ffd.urn = data.id;
    //     }
    //     ffd.itemId = data.itemId;
    //     ffd.fileName = data.fileName;
    //   }
    //   return ffd;
    // },

    /**
     * 
     * @param {Store Context} param0 
     * @returns {Promise<>} Promise following Hub list definition
     */
    async setHub({
        // dispatch,
        getters,
        commit
    }) {
        let settings = JSON.parse(JSON.stringify(getters.axiosSettings));

        settings["params"] = {
            id: "#",
            type: null
        };
        try {
            const response = await axios.get("api/forge/datamanagement", settings)
            if (response.data.length == 0) {
                return Promise.reject('no-valid-account');
            }
            commit("SET_HUB_LIST", response.data);

        } catch (error) {
            if (error == 'no-valid-account') {
                // Redirect to correct page;
                router.push({
                    path: '/not-active'
                });
                throw error;
            }
        }
    },
    setCurrentHub({
        commit,
        getters,
        dispatch
    }, data) {
        commit("SET_CURRENT_HUB", data);



        let accountId = data.id.substring(data.id.lastIndexOf("/") + 3);
        //
        return CheckOrCreateUser(accountId)
            .then((ret) => {
                commit("SET_TRIAL_MODE", ret.trialMode);
                commit("SET_ACCOUNT_KEY", ret.accountKey);
                commit("SET_ACCOUNT_ID", accountId);
                commit("SET_EXPIRATION_DATE", ret.expDate);
                commit("SET_SUBSCRIPTION_STATUS", ret.subscriptionStatus);
                dispatch("startSignalR", ret.accountKey)
                dispatch("setSlotCount");
                commit('SET_VCAD_USER_INFO', ret.userConfig);
                //Redirect if subscription is expired
                if (getters.subscriptionStatus != 'active') {
                    // Redirect to correct page;
                    router.push({
                        path: '/not-active'
                    });
                }
            });
        //
    },

    setVcadFileStatus({
        commit
    }, data) {

        // console.log("setVcadFileStatus", data);
        commit("SET_VCAD_FILE_STATUS", data);
    },


    // deleteVcadFile({
    //     getters
    // }, data) {
    //     let settings = JSON.parse(JSON.stringify(getters.axiosSettings));

    //     settings["params"] = {
    //         guid: data.guid
    //     };

    //     return axios
    //         .post("api/vcad/file/delete", null, settings)
    //         .then(function (response) {

    //             let ret = response.data;
    //             console.log("delete data", ret)

    //         })
    // },

    setCurrentViewFromB360DocumentVersion({
        commit,
    }, data) {
        let v = [{
            guid: data.viewableGuid,
            mime: '',
            name: data.viewableName,
            path: null,
            role: ''
        }];
        commit("SET_CURRENT_VIEWS", v);
    },

    retrieveDerivativeUrnFromDocVersion({

        getters,
    }, data) {

        let defs = JSON.parse(JSON.stringify(getters.axiosSettings));
        defs["params"] = {
            projectid: data.projectId,
            itemId: data.itemId
        };

        return new Promise((resolve, reject) => {

            axios.get("api/forge/getDerivativeUrnFromDocVersion", defs).then(
                function (result) {
                    resolve(result.data);
                },
                (error) => {
                    reject(error);
                }
            );
        })
    },

    setSlotUsedCount(context) {
        let vfc = context.getters.vcadFileList;


        const cnt = [...new Set(vfc.map(item => item.slotKey || item.id))];


        context.commit("SET_SLOT_USED_COUNT", cnt.length);

    },

    setSlotCount(context) {
        let apiSettings = JSON.parse(JSON.stringify(context.getters.axiosSettings));
        //apiSettings.params = { urn: itemUrn };

        return axios.get('api/vcad/slots/getslotcount', apiSettings)
            .then((res) => {
                context.commit("SET_SLOT_COUNT", res.data);
            });
    },

    getItemsUpdatedState(context, data) {

        if (context.getters.axiosErrorState.state == 1 || context.getters.currentHub == null) {
            return [];
        }

        let apiSettings = JSON.parse(JSON.stringify(context.getters.axiosSettings));
        apiSettings.headers["Content-Type"] = "application/json";
        return axios.post('api/forge/getItemsUpdatedState', data, apiSettings).then(ret => {
            return ret.data;
        })
            .catch(err => {
                console.log(err);
            });

    },

    /**
     * Get all views for a given urn
     * @param {Object} context Object that exposes the same set of methods/properties on the store instance
     * @param {String} itemUrn URN of the item to recover the views for
     */
    getViewsFromUrn(context, itemUrn) {
        let apiSettings = JSON.parse(JSON.stringify(context.getters.axiosSettings));
        apiSettings.params = {
            urn: itemUrn
        };

        return axios.get('api/forge/manifestitems', apiSettings)
            .then((res)=>{
                if(res.status == 500){
                    throw res;
                }
                return res;
            })
            .catch(err => {
                console.log(err);
            });
    },

    /**
     * Get  geometry from graphicresource for a given urn
     * @param {Object} context Object that exposes the same set of methods/properties on the store instance
     * @param {Object} data urn,graphicsresourcedata
     */
    getManifestGraphicsResourceFromGeometry(context, data) {
        let apiSettings = JSON.parse(JSON.stringify(context.getters.axiosSettings));
        apiSettings.params = data;

        return axios.get('api/forge/manifestgraphicsresourcefromgeometry', apiSettings).then((res) => {
            return res.data;
        })
            .catch(err => {
                console.log(err);
            });
    },

    /**
     * Set the current views for a given file urn.
     * @param {Object} context Object that exposes the same set of methods/properties on the store instance
     * @param {Object} data Objet in the form "{ 'urn': <String> }"
     */
    setCurrentViewsFromUrn(context, data) {
        return context.dispatch('getViewsFromUrn', data.urn)
            .then((res) => {
                context.commit("SET_CURRENT_VIEWS", res.data);
            });
    },
    // setCurrentViewsFromUrn({
    //   commit,
    //   getters,
    // }, data) {

    //   let defs = JSON.parse(JSON.stringify(getters.axiosSettings));
    //   defs["params"] = {
    //     urn: data.urn
    //   };

    //   return axios.get("api/forge/manifestitems", defs).then(
    //     function (result) {
    //       let data = result.data;
    //       commit("SET_CURRENT_VIEWS", data);

    //     }
    //   );

    /**
     * Set the currentViews for the provided BIM360 item
     */
    setCurrentViews(context, b360Item) {
        let itemTypeName = context.getters.itemTypeName;
        let documentTypeName = context.getters.documentTypeName;

        context.commit("SET_CURRENT_VIEWS", []);

        //Recover views for BIM360 item
        if (b360Item.type == itemTypeName || b360Item.versionType == itemTypeName) {
            context.dispatch('setCurrentViewsFromUrn', {
                urn: b360Item.derivativeUrn
            });
        }

        // Recover views for BIM360 document
        if (b360Item.type == documentTypeName || b360Item.versionType == documentTypeName) {
            context.dispatch('setCurrentViewFromB360DocumentVersion', b360Item)
                .then(() => {
                    if (b360Item.derivativeUrn == null) {
                        let docInfo = {
                            projectid: b360Item.projectId,
                            itemId: b360Item.itemId
                        };
                        context.dispatch('retrieveDerivativeUrnFromDocVersion', docInfo)
                            .then((derivativeUrn) => {
                                // Set derivativeUrn b360Item
                                b360Item.derivativeUrn = derivativeUrn;
                            });
                    }
                })
        }

    },


    setUserProfile({
        commit,
        getters
    }) {
        let settings = JSON.parse(JSON.stringify(getters.axiosSettings));
        return axios
            .get("api/forge/user/profile", settings)
            .then((response) => {

                let data = response.data;
          

                commit("SET_USER_PROFILE", data);
                const userFunctionalities = data.userFunctionalities || [];
                commit('SET_USER_FUNCTIONALITY', userFunctionalities);
                return response;
            })
    },

    setCurrentForgeFileData({
        commit
    }, data) {
        commit("SET_CURRENT_FORGE_FILE_DATA", data);



    },

    setCurrentForgeFileData2({
        commit
    }, data) {

        commit("SET_CURRENT_FORGE_FILE_DATA2", data);
    },


    // fetchNodes({
    //     getters,
    // }, data) {
    fetchNodes({ getters }, { item, addChildrenToParent }) {
        // let item = data.item;
        // let addChildrenToParent = data.addChildrenToParent;
        let settings = JSON.parse(JSON.stringify(getters.axiosSettings));
        settings["params"] = {
            id: item.id,
            type: item.type,
            projectId: item.projectId
        };

        console.log(item.type);

        return new Promise((resolve, reject) => {
            axios.get("api/forge/datamanagement", settings).then(
                function (result) {
                    let data = result.data;
                    data.forEach((node) => {
                        if (node.hasSubFolders == true) {
                            node["children"] = [];
                        }
                        if (
                            addChildrenToParent == true &&
                            node.type != getters.documentTypeName &&
                            node.type != getters.itemTypeName
                        ) {
                            item.children.push(node);
                        }
                    });

                    if (data == []) {
                        item.children = null;
                        data = null;
                    }

                    resolve(data);
                },
                (error) => {
                    reject(error);
                }
            );
        });


    },
    /**
     * Recover nodes for the provided item.
     * @param {vuexContext} param0 The vuex context object.
     * @param {Object} param1 Object containing the "item" to get nodes for.
     * @returns 
     */
    fetchNodesNew({ getters }, { item }) {

        let settings = JSON.parse(JSON.stringify(getters.axiosSettings));
        settings["params"] = {
            id: item.id,
            type: item.type,
            projectId: item.projectId
        };

        return axios.get("api/forge/datamanagement", settings)
            .then((result) => {
                let childNodes = result.data;
                return childNodes == [] ? null : childNodes;
            });
    },

    async getItemPath({ getters, dispatch }){
        let settings = JSON.parse(JSON.stringify(getters.axiosSettings));
        settings["params"] = {
            projectId: 'b.9c7dfd01-74f4-4d13-a530-df3c22455378',        //'b.89903d26-7ab5-4220-89f5-8d387960f7ac',
            // itemId: 'urn:adsk.wipprod:fs.folder:co.BPkhzJe8Svipu5hCPempDw',
        itemId: 'urn:adsk.wipprod:fs.folder:co.vK9xWlSaSQyv1ddZseZcJA' //urn:adsk.wipprod:fs.folder:co.itaG9wYtSaGcXh2WlK91rQ'            //'urn:adsk.wipprod:fs.folder:co.wf7-wgUxQTuE7ZcHXvEURQ',
        };

        try {
            // Get tree folders
        const result = await axios.get("api/forge/itempath", settings);
        const flatFolderTree = result.data || [];
console.log(`flatFolderTree: ${flatFolderTree}`)
        return dispatch('buildFolderTree', flatFolderTree);
        } catch (error) {
            // @TODO: Implement error management
            console.log(`ERROR: Calle exited with error.\n${error}`);
        }
        
    },
    buildFolderTree(context, folderList){
        // Remove root folder
        const indexOfRoot = folderList.indexOf(folder => folder.text.endsWith('root-folder'));
        const rootFolder = folderList.splice(indexOfRoot, 1)[0];
        let folderIds = [];

        // Create Map
        let folderDict = new Map();
        folderList.forEach(folder => {
            folderDict.set(folder.itemId, {...folder, children:[]});
            folderIds.push(folder.itemId);
        });

        // Build folder tree
        let tree = [];
        folderList.forEach(folder => {
            const isSubfolder = folder.parentId !== rootFolder.itemId;
            if(isSubfolder){
                // Add folder to it's parent
                folderDict.get(folder.parentId).children.push(folder);
            }else{
                tree.push(folderDict.get(folder.itemId));
            }
        })
        return tree;
    },


    // navigatePath({ getters }) {
    //     const folderId = 'https://developer.api.autodesk.com/data/v1/projects/b.e05a01df-30be-43bb-9228-feedb48f1e1e/folders/urn:adsk.wipprod:fs.folder:co.YNz1FERPREikezT-tm6SMg';
    //     const type = 'folders';
    //     const projectId = 'b.e05a01df-30be-43bb-9228-feedb48f1e1e';

    //     let settings = JSON.parse(JSON.stringify(getters.axiosSettings));
    //     settings["params"] = {
    //         id: folderId,
    //         type: type,
    //         projectId: projectId
    //     };

    //     return axios.get("api/forge/datamanagement", settings)
    //         .then((result) => {
    //             let childNodes = result.data;
    //             return childNodes == [] ? null : childNodes;
    //         });
    // },

    /**
     * Get the versions of a provided item of document.
     * @param {*} context 
     * @param {Object} param1 Settings for calling api
     */
    getVersions(context, {
        item
    }) {
        let settings = JSON.parse(JSON.stringify(context.getters.axiosSettings));
        settings["params"] = {
            urn: item.id,
            type: item.type,
            projectId: item.projectId
        };
        return axios.get('api/forge/versionfromparent', settings)
            .then(res => {
                let ret = res.data || [];
                return ret;
            })
            .catch(err => {
                console.log(err);
            });
    },
    clearAxiorErrorState({
        commit
    }) {

        commit("SET_AXIOS_ERROR_STATE", {
            state: 0,
            errorNr: 0,
            message: "",
            route: "",
            isBlocking: ""
        });
    },

    setAxiorErrorState({
        commit
    }, data) {

        commit("SET_AXIOS_ERROR_STATE", data);
    },

    setShowTemplates({
        commit
    }, data) {

        commit("SET_SHOW_TEMPLATES", data);
    },

    setShowPreview({
        commit
    }, data) {

        commit("SET_SHOW_PREVIEW", data.show);
        commit("SET_PREVIEW_MODE", data.mode);

    },

    setResizePreview({
        commit
    }, data) {
        commit("SET_RESIZE_PREVIEW", data.resize);
    },

    setVcadTemplateList({
        commit,
        getters
    }, data) {

        let defs = JSON.parse(JSON.stringify(getters.axiosSettings));

        defs["params"] = {
            type: data
        };

        return axios
            .get("api/vcad/templates/getlist", defs)
            .then(function (data) {
                commit("SET_VCAD_TEMPLATE_LIST", data.data);
            });
    },

    setVcadFileList({
        commit,
        getters,
        dispatch
    }) {
        if (getters.axiosErrorState.state == 1 || getters.currentHub == null) {
            return;
        }

        return axios
            .get("api/vcad/file/status", getters.axiosSettings)
            .then(function (data) {
                commit("SET_VCAD_FILE_LIST", data.data);

                //used slot calc
                dispatch("setSlotUsedCount");

                return data.data;
            });
    },

    setForgeToken({
        commit
    }, data) {

        commit("SET_FORGE_TOKEN", data);
    },

    setSelectedProject({
        commit
    }, data) {
        commit("SET_SELECTED_PROJECT", data);
    },
    setSelectedProjectByItemId({getters, commit}, itemId) {
        const project = getters.projects.find(project => project.itemId === itemId)
        commit("SET_SELECTED_PROJECT", project);
    },
    /**
     * 
     * @param {Object} context Store context in the form { getters }
     * @param {String} itemId The itemId of the requested project.
     * @returns 
     */
    getProjectByItemId({getters}, itemId){
        const project = getters.projects.find(project => project.itemId === itemId);
        return project;
    },

    setSelectedFolder({
        commit
    }, data) {
        // stop
        commit("SET_SELECTED_FOLDER", data);
    },


    loadProjects({
        commit,
        getters
    }) {

        // let th = this;
        let settings = JSON.parse(JSON.stringify(getters.axiosSettings));
        settings["params"] = {
            id: getters.currentHub.id,
            type: getters.currentHub.type
        };
        return axios
            .get("api/forge/datamanagement", settings)
            .then(function (response) {
                // console.log("data");
                let data = response.data;

                //th.loadProjects(data[0].id);
                commit('setProjects', data);
            })
            .catch();



    },

    /**
     * Set the list of Vcad managed file types.
     * @param {Object} context Object that exposes the same set of methods/properties on the store instance
     */
    setVcadManagedFileTypes(context) {
        let settings = JSON.parse(JSON.stringify(context.getters.axiosSettings));
        return axios.get('api/vcad/getVcadManagedFileTypes', settings)
            .then((res) => {
                const types = res.data.data;
                context.commit('SET_SUPPORTED_VCAD_FILE_TYPES', types);
                return res;
            })
    },

    startSignalR(context, hubid) {
        const getters = context.getters;
        const updatesHub = this._vm.$updatesHub;
        updatesHub.stopSignalR().then((v) => {
            console.log(v);

            axios.get('api/forge/user/vcadtoken?hubid=' + hubid, getters.axiosSettings)
                .then((res) => {
                    if (res.status == 200) {
                        updatesHub.startSignalR(getters.signalRbaseURL, res.data);
                    }
                });

        });

    },

    /**
     * Set content and show Snackbar.
     * @param {Object} context Object that exposes the same set of methods/properties on the store instance
     * @param {Object} config Configuration of snackbar
     */
    showSnackbar(context, { text, color, timeoutTime, onCloseFunc }) {
        let newSnack = {
            isVisible: true,
            text: text,
            color: color,
            timeoutTime: timeoutTime,
            onCloseFunc: onCloseFunc ? onCloseFunc : () => { return; }
        };
        context.commit('SET_SNACKBAR_INFO', newSnack);
    },
    resetSnackbar(context) {
        context.commit('SET_SNACKBAR_INFO', {
            isVisible: false,
            text: null,
            color: null,
            timeoutTime: null,
            onCloseFunc: null
        })
    },



};

const mainModule = {
    namespaced,
    state,
    actions,
    getters,
    mutations
}

export default mainModule;