<!------------------------------------------------
**************************************************
Author: m.vannoli
Date: 21/12/2021
Comments: Component privacy info (vcad bim360)
**************************************************
------------------------------------------------->
<template>
  <v-card class="overflow-y-auto elevation-0" max-height="55vh">
    <v-card-text>
      <strong
        ><span class="tlid-translation translation" lang="en"
          ><span title="">Pursuant to art.</span>
          <span class="" title=""
            >13 of EU Reg. 2016/679 (GDPR) and Legislative Decree 196/2003</span
          ></span
        ></strong
      >,
      <span class="tlid-translation translation" lang="en"
        ><span class="" title=""
          >which guarantees that the processing of personal is carried out in
          compliance with the fundamental rights and freedoms of each
          individual;</span
        >
        <span class="" title=""
          >suitable work procedures have been defined which safeguard the
          confidentiality of information and personal identity</span
        ></span
      >.

      <strong>Blogic s.r.l.</strong>, with registered office in via Francesco
      Mengotti, 15 00191 Rome (RM) Italy,
      <span class="tlid-translation translation" lang="en"
        ><span class="" title=""
          >in the person of the P.T. administrator, Giovanni Livi, as the owner
          of the processing of personal data, with this document discloses the
          following information on the processing of data that you will provide
          to him</span
        ></span
      >:

      <h5>Treatment</h5>

      <span class="tlid-translation translation" lang="en"
        ><span class="" title=""
          >By "treatment" of personal data, we mean any operation or set of
          operations carried out on personal data (collection, registration,
          organization, structuring, storage, adaptation, modification,
          extraction, consultation, use, communication through transmission,
          diffusion, comparison, interconnection</span
        >
        <span class="" title=""
          >, limitation, cancellation and destruction)</span
        ></span
      >

      <h5>Purpose of the treatment</h5>

      <span class="tlid-translation translation" lang="en"
        ><span class="" title=""
          >The treatment of personal data you provide will be aimed at
          communicating information and advice requested by you verbally or
          through your preferred contact methods.</span
        >

        <span class="" title=""
          >The provision of personal data is optional, therefore the absence of
          the same does not affect the</span
        >

        <span title="">response from the Owner.</span>
        <span class="" title=""
          >The aforementioned data may also be used for communications by</span
        >

        <span class="" title=""
          >different kinds, excluding marketing purposes</span
        ></span
      >.

      <h5>
        <span class="tlid-translation translation" lang="en"
          ><span class="" title="">Profiling activities</span></span
        >
      </h5>

      <strong>Blogic s.r.l.</strong>
      <span class="tlid-translation translation" lang="en"
        ><span class="" title=""
          >does not perform any profiling activity, consisting of an automated
          data processing for the purpose of categorizing individuals and their
          behavior</span
        ></span
      >.

      <h5>
        <span class="tlid-translation translation" lang="en"
          ><span class="" title=""
            >Lawfulness / legal basis of the treatment</span
          ></span
        >
      </h5>

      <span class="tlid-translation translation" lang="en"
        ><span title=""
          >The treatment of personal data is carried out on the basis of the
          consent expressed by you and will take place, for the purposes
          indicated above according to the principles of lawfulness, correctness
          and transparency, and in order to protect confidentiality through the
          preparation of technical organizational measures, in order to</span
        >
        <span class="" title=""
          >avoid unauthorized or illegal treatment, or accidental loss /
          destruction / damage related to the same data</span
        ></span
      >.

      <h5>Place of treatment</h5>

      <span class="tlid-translation translation" lang="en"
        ><span class="" title=""
          >The treatment of data will happen within the European Community,
          where the GDPR is applied</span
        ></span
      >.

      <h5>
        <span class="tlid-translation translation" lang="en"
          ><span class="" title=""
            >Categories of recipients of the data or who may become aware of
            them as Data Processors or persons authorized to process them</span
          ></span
        >
      </h5>

      <span class="tlid-translation translation" lang="en"
        ><span class="" title=""
          >For the pursuit of the purposes described above</span
        ></span
      >, <strong>Blogic s.r.l.</strong>
      <span class="tlid-translation translation" lang="en"
        ><span class="" title=""
          >does not need to transfer and / or communicate the personal data
          collected to third parties, however it reserves the right to
          communicate the aforementioned data to employees and collaborators of
          Blogic s.r.l.</span
        >
        <span title=""
          >appointed as "External Data Processors" or "Persons authorized to
          process data", Insurance Bodies only to fulfill legal or contractual
          obligations;</span
        >
        <span title="">Consultants and collaborators.</span>
        <span title=""
          >The data in question will not be otherwise disclosed</span
        ></span
      >.

      <h5>Retention times</h5>

      <span class="tlid-translation translation" lang="en"
        ><span class="" title=""
          >Data retention is linked to the fulfillment of the aforementioned
          purposes of the processing, based on current legislation and legal
          obligations</span
        ></span
      >.

      <h5>
        Personal Data collected for the following purposes and using the Vcad
        for Bim 360™ service:
      </h5>

      <strong>Google Analytics: </strong>Cookies; Usage Data

      <strong>Contact form: </strong>company name; country; email address; first
      name; last name

      <strong>Mailing list or newsletter: name;</strong> email address

      <strong>YouTube video widget:</strong> Cookies; Usage Data

      <strong>Hosting and backend infrastructure:</strong> Autodesk Bim 360™
      cloud

      <strong>Personal Data:</strong> various types of Data as specified in the
      privacy policy of the service

      <h5 style="text-align: center">CONTACT INFORMATION</h5>

      <p style="text-align: center">
        <strong>Blogic s.r.l.</strong>

        via F. Mengotti, 15 00191 Rome (RM) Italy P.Iva 05731610720

        <a href="mailto:support@bimservices.it">support@bimservices.it</a>
      </p>

      <h5>
        <span class="tlid-translation translation" lang="en"
          ><span class="" title="">Rights of the interested party</span></span
        >
      </h5>

      <strong
        ><span class="tlid-translation translation" lang="en"
          ><span class="" title="">Pursuant to EU Reg. 2016/679</span></span
        ></strong
      >, <span class="tlid-translation translation" lang="en"
        ><span title=""
          >there is the possibility to exercise specific rights, in particular:
          right of access, rectification, cancellation portability of personal
          data, as well as the right of opposition and limitation of
          treatment.</span
        >
        <span title="">The owner of Blogic s.r.l.</span>
        <span title=""
          >will take care of providing suitable feedback to any request for
          access to the rights, and of providing suitable feedback within the
          terms provided.</span
        >

        <span title=""
          >Finally, there is the right to lodge a complaint with the Privacy
          Authority to complain to one</span
        >

        <span title=""
          >violation of the regulation on the protection of personal data and
          request a verification</span
        >

        <span class="" title=""
          >of the Authority itself through the forms on the site</span
        ></span
      ><a
        title="www.garanteprivacy. it"
        href="http://www.garanteprivacy.%20it"
        target="_blank"
        rel="noopener noreferrer"
      >
        www.garanteprivacy. it</a
      >

      <h5>
        <span class="tlid-translation translation" lang="en"
          ><span class="" title="">Holder of the treatment</span></span
        >, Data Protection Officer ( RPD/ DPO)
      </h5>

      The holder of the treatment is <strong>Blogic s.r.l.</strong>, with
      registered office in via Francesco Mengotti, 15 00191 Rome (RM) Italy,
      i<span class="tlid-translation translation" lang="en"
        ><span class="" title="">n the person of the administrator P.T.</span>
        <span class="" title="">Giovanni Livi, email</span></span
      >: g.livi@blogic.it.

      <p style="text-align: center">
        <strong
          ><span class="tlid-translation translation" lang="en"
            ><span title=""
              >Having read and understood the information above, the
              undersigned</span
            >

            <span class="" title=""
              >declares to have read the information above and expresses consent
              to the processing of personal data</span
            ></span
          ></strong
        >
      </p>

      They also declare to have read the subject's rights and to be aware that
      the treatment of personal data will be carried out by
      <strong>Blogic s.r.l. </strong>
      <span class="tlid-translation translation" lang="en"
        ><span class="" title=""
          >in the ways and for the purposes indicated above</span
        ></span
      >. &nbsp;
    </v-card-text>
  </v-card>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  components: {},

  data() {
    return {};
  },

  computed: {
    ...mapGetters([
      "isLogged",
      "projects",
      "selectedProject",
      "showPreview",
      "showTemplates",
      "currentForgeFileData",
      "userProfile",
      "currentHub",
      "hubList",
      "isLoading",
      "axiosSettings",
      "slotCount",
      "vcadFileList",
      "axiosErrorState",
      "slotUsedCount",
      "trialMode",
      "subscriptionStatus",
      "expirationDate",
      "snackbarInfo",
      "snackbarIsVisible",
    ]),
  },
};
</script>

<style lang="scss" scoped>
</style>