import { HubConnectionBuilder } from '@microsoft/signalr';

export default {
    install(Vue) {
        const updatesHub = new Vue();
        Vue.prototype.$updatesHub = updatesHub;

        let connection = null;
        let startedPromise = null;
        let manuallyClosed = false;
       

        Vue.prototype.startSignalR = (baseURL, jwtToken) => {
            connection = new HubConnectionBuilder()            
                .withUrl(`${baseURL}filesupdateshub`,
                    jwtToken ? { accessTokenFactory: () => jwtToken } : null
                ).withAutomaticReconnect().build();
            connection.on('UpdateFiles', (files) => {
                updatesHub.$emit('update-files', files);
            })


            function start() {
                startedPromise = connection.start()
                    .catch(err => {
                        console.error('Failed to connect with hub', err);
                        return new Promise((resolve, reject) =>
                            setTimeout(() => start().then(resolve).catch(reject), 5000)
                        );
                    });
                startedPromise.then(() => connection.invoke('JoinGroup'));
                return startedPromise;
            }

            connection.onclose(() => {
                if (!manuallyClosed) start();
            });

            manuallyClosed = false;
            start();
        }
        Vue.prototype.stopSignalR = () => {
            if (!startedPromise) return Promise.resolve();
      
            manuallyClosed = true;
            return startedPromise
              .then(() => connection.stop())
              .then(() => { startedPromise = null; });
          }
    }
}