import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '../store/index';
import DocExplorer from "../components/DocExplorerBim360";

Vue.use(VueRouter)

const routes = [
    {
        path:'/',
        redirect: '/b360-doc-manager'
    },
    {
        path:'/b360-doc-manager',
        name: 'Autodesk Construction Cloud document manager',
        component: DocExplorer
    },
    {
        path:'/b360-doc-manager/:startTargetProjectItemId?/:startTargetFolderItemId?',
        name: 'doc-manager',
        component: DocExplorer,
        props: true,
    },
    {
        path:'/slot-manager',
        name: 'Vcad slot manager',
        component: () => import('../components/SlotManager')
    },
    {
        path:'/not-active',
        name: 'No active account',
        component: () => import('../components/NoAccountView')
    },
    {
        path:'/playground',
        name: 'Playground',
        component: () => import('../components/Playground')
    },
    {
        path:'/federation-management',
        name: 'Federation Management',
        component: () => import('../components/FederationManagement')
    },
    {
      path: '*',
      name: '404',
      component: () => import('../components/404')
    }

]

const router = new VueRouter({
    // mode: 'history',
    // base: process.env.BASE_URL,
    routes
  });

// Setup check on subscription and user rights
router.beforeEach(async (to, from, next) => {
    const localStore = store;

    // If expiration date is passed redirect or subscription is not active
    if(to.path != '/not-active'){
        const subsctiptionStatus = localStore.getters['subscriptionStatus'];
        if(subsctiptionStatus && subsctiptionStatus != 'active'){
            console.log('REDIRECT from router')
            // Redirect to correct page;
            next('/not-active');
        }else{
            next();
        }
    }

    // Set SelectedProject
    if(to.name == 'doc-manager'){
        // TODO: Manage HUB change form url

        const oldProjectId = localStore.getters.selectedProject?.itemId;
        const newProjectId = to.params.startTargetProjectItemId;
        const projectHasChanged = oldProjectId != newProjectId;
        
        if(newProjectId && projectHasChanged){
            const urlProjId = to.params.startTargetProjectItemId;
            await  localStore.dispatch('setSelectedProjectByItemId', urlProjId)
        }
        const startTargetFolderItemId = to.params.startTargetFolderItemId;
        
        if(!startTargetFolderItemId){
            await  localStore.dispatch('setSelectedFolder', null)
        }
    }

    next();
});

export default router;