
<template>
  <v-dialog v-model="dialog" persistent max-width="500">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-if="!forMenu"
        small
        outlined
        color="primary"
        elevation="2"
        v-bind="attrs"
        v-on="on"
        :loading="downloadProcessStatus"
        class="vcad-btn"
        @click="downloadFile"
        :disabled="filesItem.length<=0" 
      >
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-icon v-on="on">mdi-file-download</v-icon>
          </template>
          <span>Downlaod source file</span>
        </v-tooltip>
      </v-btn>
      <v-btn
        outlined
        small
        v-else
        v-on="on"
        :loading="downloadProcessStatus"
        color="primary"
        class="vcad-btn"
        @click="downloadFile"
        >Download</v-btn
      >
    </template>
    <v-card dense>
      <v-card-title class="text-h6">
        Download files: {{ filesItem.length ? filesItem.length : 1 }}
      </v-card-title>
      <v-list-item-group color="primary">
        <v-list-item dense v-for="(item, i) in filesProcessed" :key="i">
          <v-list-item-icon dense>
            <div v-if="item.start">
              <v-tooltip v-if="!item.check" bottom>
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on" small color="red">mdi-alert</v-icon>
                </template>
                <span>{{ item.error }}</span>
              </v-tooltip>

              <v-icon small v-else color="green">mdi-check</v-icon>
            </div>
            <v-progress-circular
              indeterminate
              :size="20"
              color="primary"
              v-else
            ></v-progress-circular>
          </v-list-item-icon>
          <v-list-item-content dense>
            {{ item.name }}
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          outlined
          small
          color=""
          class="vcad-btn"
          text
          @click="dialog = false"
          :disabled="downloadProcessStatus"
        >
          close
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "axios";
import JsZip from "jszip";
import FileSaver from "file-saver";
export default {
  props: {
    filesItem: {
      default: null,
    },
    forMenu: {
      required: false,
      default: false,
    },
  },
  data: () => ({
    downloadProcessStatus: false,
    filesProcessed: [],
    dialog: false,
  }),
  computed: {
    ...mapGetters([
      "isLogged",
      "userProfile",
      "axiosSettings",
      "forgeToken",
      "documentTypeName",
      "itemTypeName",
      "versionTypeName",
    ]),
  },
  methods: {
    /*
    Download methods: manage multiple files and single file.
    */
    async downloadFile() {
      let th = this;
      if (!th.forMenu) {
        this.filesProcessed = this.filesItem.map((ele) => {
          return {
            name: ele.fileName,
            blob: null,
            start: false,
            check: false,
            error: null,
          };
        });
        await th.downloadZipFile();
      } else {
        this.filesProcessed = [this.filesItem].map((ele) => {
          return {
            name: ele.fileName,
            blob: null,
            start: false,
            check: false,
            error: null,
          };
        });

        await this.downloadSingleFile();
      }
    },

    /*
    Download multiple files: if more to one, zip them
    */
    async downloadZipFile() {
      let th = this;
      let listUrls = await Promise.all(
        th.filesItem.map((fileItem) => {
          let id = fileItem.itemId;
          let projectId = fileItem.projectId;
          let type = fileItem.type;
          th.downloadProcessStatus = true;
          if (fileItem.type == th.documentTypeName) {
            id = fileItem.id;
          }

          let settings = JSON.parse(JSON.stringify(th.axiosSettings));

          settings["params"] = { projectId: projectId, id: id, type: type };

          return axios
            .get("api/forge/documentToDownload", settings)
            .then(function (response) {
              settings.method = "get";
              settings.url = response.data;

              settings.headers = {
                Authorization: "Bearer " + th.forgeToken.access_token,
              };

              settings.responseType = "blob";

              return axios(settings).then(function (response1) {
                return {
                  name: fileItem.fileName,
                  blob: response1.data,
                  start: true,
                  check: true,
                  error: null,
                };
              });
            })
            .catch(function (error) {
              let errorMessage = error;
              if (error.status) {
                errorMessage = error + "-" + error.status;
              }
              return {
                name: fileItem.fileName,
                blob: null,
                start: true,
                check: false,
                error: errorMessage,
              };
            });
        })
      );
      const zip = JsZip();
      th.filesProcessed = listUrls;
      th.downloadProcessStatus = false;
      let checkError = false;
      listUrls.forEach((e) => {
        if (e.error == null || "") {
          checkError = true;
        }
      });
      if (th.filesProcessed.length > 1) {
        if (checkError) {
          listUrls.forEach((data) => {
            if (data && data.blob) {
              zip.file(data.name, data.blob);
            }
          });

          zip.generateAsync({ type: "blob" }).then((zipFile) => {
            const currentDate = new Date().getTime();
            const fileName = `VCAD-${currentDate}.zip`;
            return FileSaver.saveAs(zipFile, fileName);
          });
        }
      } else {
        if (checkError) {
          var fileURL = window.URL.createObjectURL(th.filesProcessed[0].blob);
          var fileLink = document.createElement("a");
          fileLink.href = fileURL;
          fileLink.setAttribute("download", th.filesItem[0].text);
          document.body.appendChild(fileLink);
          fileLink.click();
        }
      }
    },

    /*
    Download single file
    */
    async downloadSingleFile() {
      this.downloadProcessStatus = true;
      let id = this.filesItem.itemId;
      let projectId = this.filesItem.projectId;
      let type = this.filesItem.type;
      let th = this;

      if (this.filesItem.type == th.documentTypeName) {
        id = this.filesItem.id;
      }

      let settings = JSON.parse(JSON.stringify(th.axiosSettings));

      settings["params"] = { projectId: projectId, id: id, type: type };

      return axios
        .get("api/forge/documentToDownload", settings)
        .then(function (response) {
          settings.method = "get";
          settings.url = response.data;
          settings.headers = {
            Authorization: "Bearer " + th.forgeToken.access_token,
          };

          settings.responseType = "blob";

          return axios(settings).then(function (response1) {
            var fileURL = window.URL.createObjectURL(response1.data);
            var fileLink = document.createElement("a");
            fileLink.href = fileURL;
            fileLink.setAttribute("download", th.filesItem.text);
            document.body.appendChild(fileLink);
            fileLink.click();
            th.filesProcessed = [
              {
                name: th.filesItem.fileName,
                blob: response1.DATA,
                start: true,
                check: true,
                error: null,
              },
            ];

            th.downloadProcessStatus = false;
          });
        })
        .catch(function (error) {
          let message = error;
          if (error.status) {
            message = error + "-" + error.status;
          }
          th.filesProcessed = [
            {
              name: th.filesItem.fileName,
              blob: null,
              start: true,
              check: false,
              error: message,
            },
          ];
          th.downloadProcessStatus = false;
        });
    },
  },
};
</script>

<style lang="css" scoped>
#vcad-action-list {
  background-color: #ffffff;
}
.vcad-action-item {
  min-height: 30px !important;
}
.vcad-action-item-content {
  padding-top: 2px !important;
  padding-bottom: 2px !important;
}
</style>