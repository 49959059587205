import axios from "axios";

const namespaced = false;

const state = {};

const getters = {};

const mutations = {};

const actions = {
    /**
     * Import file into Vcad. Start conversion process and occupy a slot.
     * @param {Object} context Object that exposes the same set of methods/properties on the store instance
     * @param {Object} data Object in the form { urn:String, viewableGuid:String }. Where "urn" is the Forge file urn and "viewableGuid" is the Forge viewableGuid
     */
    async setVcadImport(context, data){
        let graphRes = await context.dispatch("getManifestGraphicsResourceFromGeometry", {
            urn: data.urn,
            graphicsresource: data.viewableGuid
        });

        data.graphicResourceGuid = graphRes;
        data.info.graphicResourceGuid = graphRes;

        let defs = JSON.parse(JSON.stringify(context.rootGetters.axiosSettings));

        defs.headers["Content-Type"] = "application/json";

        return axios.post("api/vcad/file/import", data, defs)
    },
    async restartConversion(context, {slotGuid, filterId}){
       

        let defs = {...context.rootGetters.axiosSettings};

        defs.headers["Content-Type"] = "application/json";
        let data = {slotGuid, filterId}
        return axios.post("api/vcad/file/restart-conversion", data, defs)
        .then( () => {
            context.dispatch('showSnackbar',{
            text: 'Restarting file conversion',
            color: 'info',
            timeoutTime: 5000
            });
        })
        .catch((err)=>{
            console.log(err);
            if(context.rootGetters.axiosErrorState.state == 0){
                context.dispatch('showSnackbar',{
                    text: err.message,
                    color: 'error',
                    timeoutTime: 5000,
                });
            }
        })
    },
    /**
     * Delete he Vcad slot disabling all old reports.
     * @param {Object} context Object that exposes the same set of methods/properties on the store instance
     * @param {Object} data Object in the form { guid:String }. Where "guid" is the guid of the Vcad slot to delete.
     */
    deleteVcadFile(context, data){
        let settings = JSON.parse(JSON.stringify(context.rootGetters.axiosSettings));
        settings["params"] = {
            guid: data.guid
        };

        return axios.post("api/vcad/file/delete", null, settings);
    },
    /**
     * Set a specific status to a Vcad slot.
     * @param {Object} context Object that exposes the same set of methods/properties on the store instance
     * @param {Object} options Object in the form { slotGuid:String, status:Number } 
     */
    setSlotStatus(context, { slotGuid, status }){
        let apiSettings = { ...context.rootGetters.axiosSettings };
        apiSettings.headers["Content-Type"] = "application/json";
        
        let data = { slotGuid, status };
        return axios.post('api/vcad/file/status/update', data, apiSettings)
        .then( () => {
            context.dispatch('showSnackbar',{
            text: 'Restarting file conversion',
            color: 'info',
            timeoutTime: 5000
            });
        })
        .catch( (err) => {
            console.log(err);
            if(context.rootGetters.axiosErrorState.state == 0){
                context.dispatch('showSnackbar',{
                    text: err.message,
                    color: 'error',
                    timeoutTime: 5000,
                });
            }
        });
    }
}

const vcadModule = {
    namespaced,
    state,
    actions,
    getters,
    mutations
};

export default vcadModule;