<template>
    <v-main class="grey lighten-2" id="vcad-login-main">
        <v-container class="fill-height" fluid>
            <v-row justify="center" align="center">
                <v-col :cols="12" justify="center" align="center">
                    <div class="d-inline-flex" style="max-width: 38em;">
                        <v-img src="@/assets/vcadACClogo.jpg" width="180"></v-img>
                        <v-card class="elevation-0" tile>
                            <v-card-text>
                                <h3 class="text-black my-4">Welcome to <b>Vcad for Autodesk Construction Cloud<span>&#174;</span>!</b></h3>
                                <p>To use Vcad, please activate the App in Autodesk Construction Cloud<span>&#174;</span> Account Admin area. If you need more information, follow the instructions described in the <a href="https://apps.autodesk.com/BIM360/en/Detail/HelpDoc?appId=8628266868333693275&appLang=en&os=Web" target="_blank">Autodesk<span>&#174;</span> App Store page</a>.</p>
                                <p>If you need help, feel free to <a href="https://www.bimservices.it/vcad-demo-request/" target="_blank">contact us</a></p>
                            </v-card-text>
                            <v-card-actions class="d-flex align-center justify-center">
                                <v-btn
                                    @click="getAuthUrl"
                                    outlined
                                    color="primary"
                                    class="vcad-btn"
                                    
                                >LOGIN
                                    <!-- <img
                                        src="https://github.com/Autodesk-Forge/learn.forge.viewhubmodels/raw/master/img/autodesk_text.png"
                                        height="20"
                                    /> -->
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </div>
                </v-col>
            </v-row>
        </v-container>
    </v-main>
</template>

<script>
    import { mapGetters } from 'vuex';
    import axios from "axios";
    export default {
        computed:{
            ...mapGetters([
                "axiosSettings"
            ])
        },
        methods:{
            getAuthUrl() {
                axios
                    .get("api/forge/oauth/url", this.axiosSettings)
                    .then(function (response) {
                    location.href = response.data;
                    });
                }
        }
    }
</script>

<style lang="css" scoped>
    #vcad-login-main{
        background-image: url('~@/assets/login_back.jpeg');
        background-size: cover;
    }
</style>