import Vue from 'vue'
import Vuex from 'vuex'
// import axios from "axios";
// import {getFileStatus} from "../utils/VCadItemStatusUtil";
import mainModule from "./mainModule";
import authModule from "./authModule";
import vcadModule from "./vcadModule";
import multimodel from "./multimodel";


Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    'mainModule': mainModule,
    'authModule': authModule,
    'vcadModule': vcadModule,
    'multimodel':multimodel
  }
})