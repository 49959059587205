<!------------------------------------------------
**************************************************
Author: m.vannoli
Date: 21/12/2021
Comments: Component to configurate theme color (dark/ligth) vcad bim360
**************************************************
------------------------------------------------->
<template>
  <form class="pt-5 pa-5">
    <vcad-color-picker
      @saveColor="setPrimaryColorApp()"
      v-model="selectedPrimaryColor"
      label="Select the primary theme color"
    ></vcad-color-picker>
    <vcad-color-picker
      @saveColor="setSecondaryColorApp()"
      v-model="selectedSecondaryColor"
      label="Select the secondary theme color"
    ></vcad-color-picker>
    <v-row class="align-center">
      <v-col cols="6">
        <v-switch
          v-model="checkDark"
          required
          
          @change="setDarkMode()"
        >
          <template v-slot:label>
            <v-icon v-if="!checkDark">mdi-weather-night</v-icon>
            <v-icon v-if="checkDark">mdi-weather-sunny</v-icon>
            {{ !checkDark ? "Dark mode" : "Light mode" }}
          </template>
        </v-switch>
      </v-col>
      <v-col>
        <v-switch
          v-model="checkPrimaryColor"
          required
          label="Apply primary color to App Bar"
          hint="Refresh the page to visualize the changes"
          :persistent-hint="true"
        >
        </v-switch>
      </v-col>
    </v-row>
    <v-row class="mt-10">
      <v-col align="left" cols="9">
        <v-btn
          outlined
          small
          @click="resetDefault()"
          color="primary"
          class="vcad-btn"
          ><v-icon>mdi-refresh</v-icon><span>Reset default</span></v-btn
        >
        <v-btn
          outlined
          small
          @click="resetConfigurations()"
          color="primary"
          class="vcad-btn"
          ><v-icon> mdi-redo</v-icon><span>Reset previous</span></v-btn
        >
      </v-col>
      <v-col align="right" cols="3">
        <v-btn
          outlined
          small
          color="primary"
          class="vcad-btn"
          @click.stop="saveConfigurations()"
          ><v-icon>mdi-content-save</v-icon><span>Save</span></v-btn
        >
      </v-col>
    </v-row>
    <vcad-snackbar
      :value="snackbarIsVisible"
      :text="'Settings saved.'"
      :customTimeout="2000"
      customColor="primary"
      @closeSnack="closeSnack()"
    ></vcad-snackbar>
  </form>
</template>
<script>
import VcadColorPicker from "../VcadColorPicker";
import VcadSnackbar from "../VcadSnackbar";
export default {
  components: {
    "vcad-color-picker": VcadColorPicker,
    "vcad-snackbar": VcadSnackbar,
  },
  data() {
    return {
      checkDark: false, // flag dark mode
      selectedPrimaryColor: "#1565C0", // primary color
      selectedSecondaryColor: "#D84315", // secondary color
      checkPrimaryColor: false, //flag apply to app bar
      snackbarIsVisible: false, // flag check snackbar
    };
  },
  methods: {
    /**
     * set the primary color to primary (Theme Vcad App -> Color Vuetify)
     */
    setPrimaryColorApp() {
      this.$vuetify.theme.themes.light.primary = this.selectedPrimaryColor;
      this.$vuetify.theme.themes.dark.primary = this.selectedPrimaryColor;
    },

    /**
     * set the secondary color to primary (Theme Vcad App -> Color Vuetify)
     */
    setSecondaryColorApp() {
      this.$vuetify.theme.themes.light.secondary = this.selectedSecondaryColor;
      this.$vuetify.theme.themes.dark.secondary = this.selectedSecondaryColor;
    },

    /**
     * set Dark Mode
     */
    setDarkMode() {
      this.$vuetify.theme.dark = this.checkDark;
      //localStorage.darkMode = this.$vuetify.theme.dark;
      if (!this.checkDark) {
        this.selectedPrimaryColor = this.$vuetify.theme.themes.light.primary;
        this.selectedSecondaryColor =
          this.$vuetify.theme.themes.light.secondary;
      } else {
        this.selectedPrimaryColor = this.$vuetify.theme.themes.dark.primary;
        this.selectedSecondaryColor = this.$vuetify.theme.themes.dark.secondary;
      }
    },

    /*
    Save all configurations
    */
    saveConfigurations() {
      localStorage.darkMode = this.checkDark;
      localStorage.setPrimaryColorAppBar = this.checkPrimaryColor;
      localStorage.secondaryColor = this.selectedSecondaryColor;
      localStorage.primaryColor = this.selectedPrimaryColor;
      this.snackbarIsVisible = true;
    },

    /**
     * close snackbar
     */

    closeSnack() {
      this.snackbarIsVisible = false;
    },

    /**
     * reset configuration ( previuos values)
     */
    resetConfigurations() {
      this.checkDark = localStorage.darkMode == "true" ? true : false;
      this.checkPrimaryColor = localStorage.setPrimaryColorAppBar
        ? localStorage.setPrimaryColorAppBar.toLowerCase() === "true"
        : false;
      this.selectedSecondaryColor = localStorage.secondaryColor
        ? localStorage.secondaryColor
        : "#8b0202";
      this.selectedPrimaryColor = localStorage.primaryColor
        ? localStorage.primaryColor
        : "#303F9F";
      this.setPrimaryColorApp();
      this.setSecondaryColorApp();
      this.setDarkMode();
    },

    /**
     * reset default values
     */
    resetDefault() {
      this.checkDark = false;
      this.checkPrimaryColor = false;
      this.selectedSecondaryColor = "#8b0202"; // blue
      this.selectedPrimaryColor = "#303F9F"; // vcad color
      this.setPrimaryColorApp();
      this.setSecondaryColorApp();
      this.setDarkMode();
    },
  },

  mounted() {
    this.checkDark = this.$vuetify.theme.dark;
    if (!this.checkDark) {
      this.selectedPrimaryColor = this.$vuetify.theme.themes.light.primary;
      this.selectedSecondaryColor = this.$vuetify.theme.themes.light.secondary;
    } else {
      this.selectedPrimaryColor = this.$vuetify.theme.themes.dark.primary;
      this.selectedSecondaryColor = this.$vuetify.theme.themes.dark.secondary;
    }
    let setPrimarycolorAppBarVar = localStorage.setPrimaryColorAppBar;
    this.checkPrimaryColor = setPrimarycolorAppBarVar
      ? localStorage.setPrimaryColorAppBar.toLowerCase() === "true"
      : false;
  },
};
</script>

<style lang="scss" scoped>
</style>