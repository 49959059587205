import store from "../store";
import axios from "axios";

export class VcadFolder {
	id = "";
	itemId;
	text;
	type;
	projectId;
	parentId;
	children = [];

	constructor(id, itemId, text, type, projectId, parentId, children) {
		if (!itemId && id && typeof id === "string") {
			itemId = id.split('/').at(-1);
		}
		this.id = id;
		this.itemId = itemId;
		this.text = text;
		this.type = type;
		this.projectId = projectId;
		this.parentId = parentId;
		this.children = children || [];
	}
	static parseAccFolder(accFolder) {
		let itemId = accFolder.itemId;
		let id = accFolder.id;
		if (!itemId && id && typeof id === "string") {
			itemId = id.split('/').at(-1);
		}
		return new this(
			id,
			itemId,
			accFolder.text,
			accFolder.type,
			accFolder.projectId,
			accFolder.parentId,
			accFolder.children || [],
		)
	}
}
export default class {
	/**
	 * Load the root folders of a project.
	 * @param {jsTreeNode} project The project
	 * @returns list of root folders
	 */
	async getProjectRootFolders(project) {
		let axiosSettings = { ...store.getters.axiosSettings };
		// axiosSettings.params = {
		// 	id: project.id,
		// 	type: project.type,
		// 	projectId: project.itemId
		// };
		axiosSettings["params"] = {
			id: project.id,
			type: project.type,
			projectId: project.itemId
		};

		try {
			const result = await axios.get("api/forge/datamanagemen", axiosSettings);
			const rootFolders = result.data.filter((node) => node.type == "folders") || [];
			return rootFolders;
		} catch (error) {
			// @TODO: Manage erros
			console.log(`ERROR: Calle exited with error.\n${error}`);
		}
	}
	/**
	 * Get the folders that constitute and items path.
	 * @param {string} projectId Reference to the item's project.
	 * @param {string} itemId Reference to the item id.
	 * @returns list of folder objects.
	 */
	async getItemPathFolders(projectId, itemId = null) {
		let axiosSettings = { ...store.getters.axiosSettings };
		axiosSettings.params = { projectId, itemId };
		try {
			const result = await axios.get("api/forge/itempath", axiosSettings);
			let folderList = result.data || [];
			// Remove root folder if present
			let indexOfRoot = folderList.findIndex(folder => folder.text.endsWith('root-folder'));
			if (indexOfRoot > -1) {
				folderList.splice(indexOfRoot, 1)[0];
			}
			// Parse folders 
			folderList = folderList.map(folderData => VcadFolder.parseAccFolder(folderData));
			return folderList;
		} catch (error) {
			// @TODO: Manage erros
			console.log(`ERROR: Call exited with error.\n${error}`);
		}
	}
	async getItemPathString(projectId, itemId) {
		try {
			const settings = { ...store.getters.axiosSettings };
			settings["params"] = {
				projectId: projectId,
				itemId: itemId,
			};
			const result = await axios
				.get("api/forge/item-path-string", settings)
			return result.data
		} catch (error) {
			console.error(error);
			return null;
		}
	}
	/**
	 * Build a tree from the provided folder list.
	 * The function expects the list to contain an entry for the rootFolder
	 * @param {any[]} folderList list of folders that contitute a tree.
	 */
	folderListToTree(folderList) {
		folderList = folderList.sort((a, b) => a.text.toLocaleLowerCase() >= b.text.toLocaleLowerCase() ? 1 : -1)
		// Create Map
		let folderDict = new Map();
		folderList.forEach(folder => {
			folderDict.set(folder.itemId, { ...folder, children: [] });
		});

		// @NOTE: Second level folders are lost in the transformation...

		// Build folder tree
		let tree = [];
		folderList.forEach(folder => {
			const isSubfolder = folderDict.has(folder.parentId);
			if (isSubfolder) {
				// Add folder to it's parent
				const childFolder = folderDict.get(folder.itemId);
				const parentFolder = folderDict.get(folder.parentId);
				parentFolder.children.push(childFolder);
			} else {
				tree.push(folderDict.get(folder.itemId));
			}
		})
		return tree;
	}
	async test() {
		const projectId = 'b.89903d26-7ab5-4220-89f5-8d387960f7ac';
		const itemId = 'urn:adsk.wipprod:fs.folder:co.BJ25-p8zQoyOneHWuWFxtg';

		let axiosSettings = { ...store.getters.axiosSettings };
		axiosSettings.params = { projectId, itemId };
		try {
			const result = await axios.get("api/forge/itempath-test", axiosSettings);
			console.log(result);
		} catch (err) {
			console.log(err);
		}
	}
}

