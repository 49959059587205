<template>
  <div>
    <v-select
      label="View"
      v-model="selectedView"
      return-object
      item-text="name"
      item-key="guid"
      :items="vcadCurrentViews"
      outlined
      dense
      class="mt-1"
    >
      <template v-slot:selection="{ item }">
        <span>{{ item.name }}</span>
        <span class="grey--text text-caption ml-10">
          ( {{ vcadCurrentViews.length }} views)
        </span>
      </template>
    </v-select>

    <!--TO EXPAND ROWS : prop to v-data-table: show-expand-->
    <v-data-table
      class="elevation-5 v-table-scrollable"
      :headers="headers"
      :items="vcadTemplateList"
      :show-select="false"
      item-key="name"
      loading="true"
      dense
      disable-pagination
      hide-default-footer
    >
      <!-- <template v-slot:expanded-item="{ headers, item }">
      <td :colspan="headers.length">
        Name :  {{item.name}} /  Pdf present : {{item.isPdfPresent}} / Type : {{item.type}}
      </td>
    </template>-->
      <template v-slot:[`item.name`]="{ item }">
        {{ item.label || item.name }}
      </template>
      <template v-slot:[`item.version`]="{ item }">
        <v-chip class="elevation-3 mr-1 ml-1 primary" label small>
          V {{ printVersion(item) }}
        </v-chip>
      </template>
      <template v-slot:[`item.action`]="{ item }">
        <span class="action">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <!-- <v-icon @click="downloadTemplate(item)" v-on="on"
                >mdi-cloud-download</v-icon
              > -->
              <v-btn
                icon
                :disabled="isDownloading"
                @click="downloadTemplate(item)"
                v-on="on"
              >
                <v-icon>mdi-cloud-download</v-icon>
              </v-btn>
            </template>
            <span>Download template</span>
          </v-tooltip>
          <template v-if="item.isPdfPresent">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <!-- <v-icon @click="downloadHelp(item)" v-on="on"
                  >mdi-file-pdf-outline</v-icon
                > -->
                <v-btn
                  icon
                  :disabled="isDownloading"
                  @click="downloadHelp(item)"
                  v-on="on"
                  color="primary"
                >
                  <v-icon>mdi-file-pdf-outline</v-icon>
                </v-btn>
              </template>
              <span>Download help file</span>
            </v-tooltip>
          </template>
        </span>
      </template>
    </v-data-table>

    <v-text-field
      class="mt-10"
      v-if="authModeOn == true"
      v-model="fileKey"
      :append-icon="showKey ? 'mdi-eye' : 'mdi-eye-off'"
      append-outer-icon="mdi-content-copy"
      :type="showKey ? 'text' : 'password'"
      name="txtFileKey"
      label="API Key"
      readonly
      @click:append-outer="copyText"
      @click:append="showKey = !showKey"
    ></v-text-field>

    <v-progress-linear v-if="isDownloading" indeterminate></v-progress-linear>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import axios from "axios";
import { VcadSlotKey } from "../utils/VcadSlotKey";
import sanitizefilename from "sanitize-filename";

export default {
  mounted() {
    // if(this.currentForgeFileData.vcadItem && this.currentForgeFileData.vcadItem.type){
    //   this.setVcadTemplateList(this.currentForgeFileData.vcadItem.type);
    // }
    this.setDefaultView();
    this.setkey();
  },

  props: {
    vcadData: {
      default: null,
      validator: (propVal) =>{
        const propValCheck = typeof propVal === "object";
        const propValNameCheck = propVal.name && typeof propVal.name === "string";
        const propValTypeCheck =propVal.type && typeof propVal.type === "number";
        const propValIdCheck =propVal.id && typeof propVal.id === "string";

        if(propValCheck && propValTypeCheck && propValNameCheck && propVal.authType && propValIdCheck){
          return true
        }
        return false
      },
      required: false,
    },
    vcadViewList: {
      default: null,
    },
    federatedSlotGuids: {
      type: Array,
      required: false,
      validator: (propVal) => {
        if (!Array.isArray(propVal)) {
          return false;
        }
        return propVal.every((item) => typeof item === "string");
      },
    },
  },

  watch: {
    vcadCurrentViews() {
      this.setDefaultView();
      this.setkey();
      this.showKey = false;
    },
  },

  data() {
    return {
      showKey: false,
      fileKey: "",
      selectedView: null,
      headers: [
        {
          text: "Template",
          align: "center",
          sortable: true,
          value: "name",
          width: "30%",
          class: "primary--text text-uppercase",
        },
        {
          text: "Description",
          align: "center",
          sortable: true,
          value: "description",
          width: "50%",
          class: "primary--text text-uppercase",
        },
        {
          text: "Version",
          align: "center",
          sortable: true,
          value: "version",
          width: "20%",
          class: "primary--text text-uppercase",
        },
        {
          text: "",
          align: "left",
          sortable: false,
          value: "action",
          width: "10%",
          class: "primary--text text-uppercase",
        },
      ],
      isDownloading: false,
    };
  },

  methods: {
    ...mapActions(["showSnackbar"]),

    /* Download view of templates =>
     * Url : https://bimapi.vcad.it/File/GetFile?guidFile={guidFile}&type=csvzip&x-vcad-auth={userkey}
     * API: api/vcad/file/getFile
     * params : guidFile - type - viewableGuid
     * Type : Get
     * method : method to call this url with right parameters and download csv for specific guidfile and guid of view
     * ps: only for revit files.
     * author: m.vannoli
     * date: 15/12/2021
     */
    async downloadCsvZipViews() {
      let viewableGuid = this.selectedView.guid;
      let guidFile = this.vcadItem.id;
      let th = this;
      let defs = JSON.parse(JSON.stringify(th.axiosSettings));
      defs["responseType"] = "blob";
      defs["params"] = {
        guidFile: guidFile,
        type: "csvzip",
        viewableGuid: viewableGuid,
      };
      let viewName = "";
      if (th.vcadItem.type == 7 || th.vcadItem.type == 8) {
        viewName = "_" + sanitizefilename(th.selectedView.name);
      }

      //call Api
      return axios
        .get("api/vcad/file/getFile", defs)
        .then((data) => {
          var fileURL = window.URL.createObjectURL(
            new Blob([data.data], { type: "application/x-zip-compressed" })
          );

          //file name + extension (.zip)
          var fileName =
            th.vcadItem.name.substr(
              0,

              th.vcadItem.name.lastIndexOf(".")
            ) +
            viewName +
            ".zip";
          var fileLink = document.createElement("a");
          fileLink.href = fileURL;
          fileLink.setAttribute("download", fileName);
          document.body.appendChild(fileLink);
          fileLink.click();
        })
        .then(() => {
          return false; //return false to loading button in App.vue
        });
    },

    async setkey() {
      let fileGuid;
      this.fileKey = "";
      if (this.authModeOn) {
        try {
          // IF the user is downloading a template for a federation
          if (
            this.vcadItem?.isFederation &&
            this.federatedSlotGuids?.length > 0
          ) {
            fileGuid = this.federatedSlotGuids[0];
          } else {
            // IF the user is downloading a template for a single file
            fileGuid = this.vcadItem.id;
          }
          this.fileKey = await VcadSlotKey(fileGuid).then((res) => {
            if (res.error !== 0) {
              throw res.descr;
            }
            if (!res.descr) {
              throw "Error loading user report key.";
            }
            return res.descr;
          });
        } catch (error) {
          this.showSnackbar({
            text: "Error loading user report key.",
            color: "error",
            timeoutTime: 2000,
          });
          console.error(error);
        }
      }
    },
    copyText() {
      navigator.clipboard.writeText(this.fileKey);
      this.showSnackbar({
        text: "Copied key to clipboard.",
        color: "primary",
        timeoutTime: 4000,
      });
    },
    setDefaultView() {
      this.selectedView = null;
      if (this.vcadCurrentViews && this.vcadCurrentViews.length > 0) {
        let defaultView = this.vcadCurrentViews.find((o) => o.name == "{3D}");
        // if (defaultView) {
        //   this.selectedView = defaultView;
        // }
        this.selectedView = defaultView
          ? defaultView
          : this.vcadCurrentViews[0];
      }
    },
    downloadTemplate(item) {
      console.log("downloadTemplate viewguid: " + this.selectedView.guid);
      this.isDownloading = true;

      let defs = JSON.parse(JSON.stringify(this.axiosSettings));
      defs["responseType"] = "blob";
      defs["params"] = {
        template: item.name,
        guidFile: this.vcadItem.id,
        type: this.vcadItem.type,
        viewableGuid: this.selectedView.guid,
      };
      let th = this;

      let viewName = "";

      if (this.vcadItem.type == 7 || this.vcadItem.type == 8) {
        viewName = "_" + sanitizefilename(this.selectedView?.name);
      }
      axios
        .get("api/vcad/templates/configure", defs)
        .then((data) => {
          var fileURL = window.URL.createObjectURL(
            new Blob([data.data], { type: "application/x-zip-compressed" })
          );
          var fileName =
            th.vcadItem.name.substr(0, th.vcadItem.name.lastIndexOf(".")) +
            viewName +
            "__" +
            item.name;

          var fileLink = document.createElement("a");
          fileLink.href = fileURL;
          fileLink.setAttribute("download", fileName);
          document.body.appendChild(fileLink);
          fileLink.click();
        })
        .then(() => {
          this.isDownloading = false;
        });
    },
    downloadHelp() {},
    printVersion(item) {
      if (item._Version) {
        return item._Version;
      }
      return "";
    },
  },

  computed: {
    ...mapGetters([
      "axiosSettings",
      "projects",
      "selectedProject",
      "showPreview",
      "vcadTemplateList",
      "currentForgeFileData",
      "currentViews",
    ]),

    vcadCurrentViews() {
      return this.vcadViewList ? this.vcadViewList : this.currentViews;
    },
    vcadItem() {
      return this.vcadData ? this.vcadData : this.currentForgeFileData.vcadItem;
    },
    authModeOn() {
      return this.vcadItem?.authType == 1 || this.vcadItem?.authType == 3;
    },
  },
};
</script>

<style scoped lang="scss">
.v-table-scrollable > .v-data-table__wrapper {
  overflow: auto;
  max-height: 30vh;
}
</style>