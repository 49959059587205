/* eslint-disable */
import VcadMarkupPanel from "./VcadMarkupPanel";

export default function (AutodeskViewing) {
    return class MarkUp3DExtension extends AutodeskViewing.Extension {
        constructor(viewer, options) {
            super(viewer, options);
            this.viewer = viewer;
            this.subToolbar;
            this.active = false;
            this.customize = this.customize.bind(this);
     
            this.size = 26; // markup size.  Change this if markup size is too big or small
          
            this._width = 0;
            this._height = 0;
           
            this._buffer = null;
            this._rtTexture = null;
            this._renderer = null;
            this._overlayName = "markups"
            
            //  this.scene = this.viewer.impl.sceneAfter; // change this to viewer.impl.sceneAfter with transparency, if you want the markup always on top.
            this.markupItems = []; // array containing markup data
            this.pointCloud; // three js point-cloud mesh object
            this.line3d; // three js point-cloud mesh object
            this.camera = this.viewer.impl.camera;
            this.hovered; // index of selected pointCloud id, based on markupItems array
            this.selected; // index of selected pointCloud id, based on markupItems array
            this.label; // x,y div position of selected pointCloud. updated on mouse-move
            this.offset; // global offset

            // Binding methods

            this.initMesh_PointCloud = this.initMesh_PointCloud.bind(this);
            this.setMarkupData = this.setMarkupData.bind(this);
            this.update_Line = this.update_Line.bind(this);
            this.update_DivLabel = this.update_DivLabel.bind(this);
            this.setupUI = this.setupUI.bind(this);

            ///

            this.dataDict = {};

            this.panel = null;



            // Shaders
            this.vertexShader = `
        uniform float size;
        varying vec3 vColor;
        void main() {
            vColor = color;
            vec4 mvPosition = modelViewMatrix * vec4( position, 1.0 );
            gl_PointSize = size;
            gl_Position = projectionMatrix * mvPosition;
        }
    `;

            this.fragmentShader = `
        uniform sampler2D tex;
        varying vec3 vColor;
        void main() {
            gl_FragColor = vec4( vColor.x, vColor.x, vColor.x, 1 );
            gl_FragColor = gl_FragColor * texture2D(tex, vec2((gl_PointCoord.x+vColor.y*1.0)/4.0, 1.0-gl_PointCoord.y));
            if (gl_FragColor.w < 0.5) discard;
        }
    `;

        }

        load() {

            this.viewer.addEventListener(Autodesk.Viewing.CAMERA_CHANGE_EVENT, this.onCameraChanged.bind(this));

            if (this.viewer.toolbar) {
                // Toolbar is already available, create the UI
                //// console.log("toolbar_createui");
                this.createUI();
            } else {
                // Toolbar hasn't been created yet, wait until we get notification of its creation
                this.onToolbarCreatedBinded = this.onToolbarCreated.bind(this);
                this.viewer.addEventListener(AutodeskViewing.TOOLBAR_CREATED_EVENT, this.onToolbarCreatedBinded);
            }

            console.log('MarkUp3DExtension was loaded!');
            this.viewer.addEventListener(Autodesk.Viewing.GEOMETRY_LOADED_EVENT,
                this.customize);

            return true;
        }
        unload() {
            console.log('MarkUp3DExtension is now unloaded!');

            return true;
        }


        onToolbarCreated() {
            this.viewer.removeEventListener(AutodeskViewing.TOOLBAR_CREATED_EVENT, this.onToolbarCreatedBinded);
            this.onToolbarCreatedBinded = null;
            //  this.createUI();
        };


        createUI() {

            let viewer = this.viewer;
            let th = this;

            // Button 1
            //eslint-disable-next-line
            let button1 = new AutodeskViewing.UI.Button('vcad-markup-button');
            button1.onClick = function () {

                th.active = !th.active;
                button1.setState(th.active == true ? Autodesk.Viewing.UI.Button.State.ACTIVE : Autodesk.Viewing.UI.Button.State.INACTIVE);

                if (th.active == true) {
                    th.loadData();
                } else {
                    th.unloadData();
                }

            };
            //  button1.addClass('my-view-front-button');
            button1.setToolTip('Vcad Markup');
            button1.setIcon('adsk-icon-roll');

            // SubToolbar
            //eslint-disable-next-line
            this.subToolbar = new AutodeskViewing.UI.ControlGroup('vcad-markup-toolbar');
            this.subToolbar.addControl(button1);
            viewer.toolbar.addControl(this.subToolbar);
        };

        setData(data) {
            this.externalData = data;
        }

        loadData() {
            //const dbIds = [ 3907, 3949, 3961, 3939, 3950, 3962];
            //const dbIds = [ 3907, 3949, 3961, 3939];
            this.pixels = window.devicePixelRatio || 1.0;
            this.scene = this.viewer.impl.sceneAfter; // change this to viewer.impl.sceneAfter with transparency, if you want the markup always on top.
            this.dataDict = {};
            const dbIds = [];


            this.externalData.forEach(element => {
                if (this.dataDict[element.eid] == null) {
                    dbIds.push(element.eid);
                    this.dataDict[element.eid] = [];
                }

                this.dataDict[element.eid].push(element);

            });


            const model = this.viewer.model;

            const instanceTree = model.getData().instanceTree;
            const globalOffset = model.getData().globalOffset;
            const fragList = model.getFragmentList();
            this.sampleData = [];

            for (let i = 0; i < dbIds.length; i++) {
                const dbId = dbIds[i];
                let bounds = new THREE.Box3();
                let box = new THREE.Box3();

                instanceTree.enumNodeFragments(dbId, (fragId) => {
                    fragList.getWorldBounds(fragId, box);
                    bounds.union(box);
                }, true);

                const worldPoint = new THREE.Vector3(
                    (bounds.max.x + bounds.min.x) / 2 + globalOffset.x,
                    (bounds.max.y + bounds.min.y) / 2 + globalOffset.y,
                    (bounds.max.z + bounds.min.z) / 2 + globalOffset.z + 0.5
                );

                console.log(worldPoint);

                this.sampleData.push({
                    name: "point_" + worldPoint.x,
                    icon: 0, //i, // Math.round(Math.random()*3),  
                    dbId: dbId,
                    position: {
                        x: worldPoint.x,
                        y: worldPoint.y,
                        z: worldPoint.z
                    }
                });
            }

            let size = this.sampleData.length;
            this._attbVertices = new Float32Array(size * 3);
            this._indexDbId = {};

            ////////////////////////7

            this.setMarkupData(this.sampleData);

            // hide rooms bodies, to not hinder markup icon clicking
            //this.viewer.hide(370);
            this.viewer.disableSelection(true);
            this.viewer.disableHighlight(true);
            this.viewer.impl.invalidate(true);
        }

        unloadData() {
            this.sampleData = [];
            this._indexDbId = {};
            this._attbVertices = null;
            this.scene.remove(this.pointCloud);
            this.pointCloud = null;
            //this.viewer.hide(370);
            this.viewer.disableSelection(false);
            this.viewer.disableHighlight(false);
            this.viewer.impl.invalidate(true);
        }


        customize() {
            // var self = this;
            this.viewer.removeEventListener(Autodesk.Viewing.GEOMETRY_LOADED_EVENT,
                this.customize);

            // Specific code goes here
            this.offset = this.viewer.model.getData().globalOffset; // use global offset to align pointCloud with lmv scene

            this.setupUI();

            return true;

        }


        setupUI() {

       
            document.addEventListener('mousedown', e => {
                this.onClick(e)
            }, true);
            document.addEventListener('touchstart', e => {
                this.onClick(e.changedTouches[0])
            }, false);
            document.addEventListener('mousemove', e => {
                this.onMouseMove(e)
            }, false);
            document.addEventListener('touchmove', e => {
                this.onMouseMove(e.changedTouches[0])
            }, false);
            document.addEventListener('mousewheel', e => {
                this.onMouseMove(e)
            }, true);
        }

        updateHitTest(event) {
            
            if (!this.pointCloud) return;
           
            if (this.hovered) {
                this.geometry.colors[this.hovered].r = 1.0;
                this.hovered = null;
            }

            let ret = false
            const rect = this.viewer.canvas.getBoundingClientRect();
            const position = {
                x: rect.left,
                y: rect.top,
                w: rect.width,
                h: rect.height
            };

            const pt = this.getScreenPt(event, position)

            // console.log("pt", pt.x + ' ' + pt.y);

            const val = this.getID(pt.x, pt.y);

            console.log("vv", val)
            return

        
                console.log("found: id ", idx + " dbid " + this._indexDbId[idx]);
                this.hovered = nodes[0].index;
                this.geometry.colors[this.hovered].r = 2.0;
                this.geometry.colorsNeedUpdate = true;
                this.viewer.impl.invalidate(true);

                ret = true;

 

            return ret
        }

        getVectorAttribute(attb, idx) {
            return new THREE.Vector3(attb[idx * 3], attb[idx * 3 + 1], attb[idx * 3 + 2]);
        }

        setVectorAttribute(attb, idx, vector) {
            attb[idx * 3] = vector.x;
            attb[idx * 3 + 1] = vector.y;
            attb[idx * 3 + 2] = vector.z;
        }

      
        getScreenPt(event, position) {
            const x = event.clientX ? event.clientX - position.x : event.canvasX;
            const y = event.clientY ? event.clientY - position.y : event.canvasY;
            return new THREE.Vector2(x, y);
        }


        // Load markup points into Point Cloud
        setMarkupData(data) {

            let th = this;
            this.markupItems = data;

            this.geometry = new THREE.Geometry();
            data.map((item, index) => {

                let point = (new THREE.Vector3(item.position.x, item.position.y, item.position.z));
                this.geometry.vertices.push(point);
                this.geometry.colors.push(new THREE.Color(1.0, item.icon, 0)); // icon = 0..2  in thpositione horizontal icons.png sprite sheet

                this.setVectorAttribute(th._attbVertices, index, point);
                this._indexDbId[index] = item.dbId;
            });


            this.initMesh_PointCloud();
          
        };


        initMesh_PointCloud() {
            if (this.pointCloud) {
                this.scene.remove(this.pointCloud); //replace existing pointCloud Mesh
            }

            this.init();
            let ssize = this.markupItems.length + 1
            if (!this._refImageGeom) {
                this._refImageGeom = new THREE.BufferGeometry();
                this._refImageGeom.isPoints = true;
                this._refImageGeom.offsets[0] = {
                    index: 0,
                    start: 0,
                    count: ssize
                };
                this.addAttributes(this._refImageGeom, true);
            }

            // this._idx = new Float32Array(ssize);
            // const positions = this._refImageGeom.attributes.position.array;
            // for (let i = 0; i < ssize; ++i) {
            //     const isZero = (positions[i * 3] === 0 && positions[i * 3 + 1] === 0 && positions[i * 3 + 2] === 0);
            //     this._idx[i] = isZero ? -1 : i;
            // }
            // this._refImageGeom.addAttribute('idx', new THREE.BufferAttribute(this._idx, 1));


            // create new point cloud material
            this.viewer.overlays.addScene(this._overlayName);



            let texture = THREE.ImageUtils.loadTexture("/markupIcons.png");

            let texture2 = THREE.ImageUtils.loadTexture("/markupIcons.png");
            let material = new THREE.ShaderMaterial({
                vertexColors: THREE.VertexColors,
                fragmentShader: this.fragmentShader,
                vertexShader: this.vertexShader,
                depthWrite: true,
                depthTest: false,
                uniforms: {
                    size: {
                        type: "f",
                        value: this.size
                    },
                    tex: {
                        type: "t",
                        value: texture
                    }
                }
            });


            let material2 = new THREE.ShaderMaterial({
                vertexColors: THREE.VertexColors,
                fragmentShader: this.fragmentShader,
                vertexShader: this.vertexShader,
                depthWrite: true,
                depthTest: false,
                uniforms: {
                    size: {
                        type: "f",
                        value: this.size
                    },
                    tex: {
                        type: "t",
                        value: texture2
                    }
                }
            });


            var material1 = new THREE.PointCloudMaterial({
                color: 0x880000
            });
            material1.depthWrite = true
            material.depthTest = false

            const materialManager = this.viewer.impl.matman();

            materialManager.addMaterial('pointCloudMaterial', material, true /* skip material heuristics */ );
            materialManager.addMaterial('pointCloudMaterial2', material2, true /* skip material heuristics */ );
            this.pointCloud = new THREE.PointCloud(this.geometry, material);

            this.pointCloud.position.sub(this.offset);
            this.pointCloud.matrixAutoUpdate = true;



            this.pointCloud1 = new THREE.PointCloud(this._refImageGeom, material2);
            this.pointCloud1.position.sub(this.offset);
            this.pointCloud1.matrixAutoUpdate = true;

            //var points = new THREE.Points( _refImageGeom, material );

            //this.scene.add(this.pointCloud);

            this.viewer.overlays.addMesh(this.pointCloud, this._overlayName);

            this._sceneIDRef.add(this.pointCloud1)


            //this.pointCloud.geometry.boundingBox = null;

            // this.geometry.computeBoundingBox();

            // let bb = this.pointCloud.geometry.boundingBox;

            // if (bb.max.x == bb.min.x && bb.max.y == bb.min.y && bb.max.z == bb.min.z)
            // {
            //     bb.max.x = bb.min.x +3;
            //     bb.max.y = bb.min.y +3;
            //     bb.max.z = bb.min.z +3;
            //     bb.min.x = bb.min.x -3;
            //     bb.min.y = bb.min.y -3;
            //     bb.min.z = bb.min.z -3;
            // }


            this.viewer.impl.invalidate(true);



        }

        addAttributes(geometry, forPointCloud = false) {
            geometry.addAttribute('position', new THREE.BufferAttribute(this._attbVertices, 3));
            geometry.addAttribute('visible', new THREE.BufferAttribute(this._attbVisibles, 1));
            geometry.addAttribute('nDir', new THREE.BufferAttribute(this._attbNDirs, 3));
            geometry.addAttribute('offset', new THREE.BufferAttribute(this._attbOffsets, 1));
            geometry.addAttribute('type', new THREE.BufferAttribute(this._attbTypes, 1));
            if (!forPointCloud) {
                geometry.addAttribute('outerColor', new THREE.BufferAttribute(this._attbOuterColors, 3));
                geometry.addAttribute('innerColor', new THREE.BufferAttribute(this._attbInnerColors, 3));
                geometry.addAttribute('hovered', new THREE.BufferAttribute(this._attbHovereds, 1));
            }
        }

      

        update_Line() {
            // let position = this.pointCloud.geometry.vertices[this.selected].clone();
            // this.line3d.geometry.vertices[0] = position;
            // this.line3d.geometry.vertices[1].set(position.x + this.labelOffset.x * Math.sign(position.x), position.y + this.labelOffset.y, position.z + this.labelOffset.z);
            // this.line3d.geometry.verticesNeedUpdate = true;
        }

        update_DivLabel(event) {

            if (this.hovered) {
                const rect = this.viewer.canvas.getBoundingClientRect();
                const position = {
                    x: rect.left,
                    y: rect.top,
                    w: rect.width,
                    h: rect.height
                };

                const pt = this.getScreenPt(event, position)
                this.panel.setPosition(pt.x + 30, pt.y);

                return

                //         let position = this.pointCloud.geometry.vertices[this.hovered].clone();
                //   //  let position = this.line3d.geometry.vertices[1].clone().sub(this.offset);



                //         //let selectedData = this.sampleData[this.selected];

                //      let posx =  (position.x + this.labelOffset.x * Math.sign(position.x));               
                //      let posy =  position.y + this.labelOffset.y;
                //      let posz =  position.z + this.labelOffset.z;



                //    let newpos =  new THREE.Vector3(posx, posy, posz).sub(this.offset);
                //     this.label = newpos.project(this.camera);


                //         this.panel.setPosition( this.label.x,this.label.y);

                //         // window.dispatchEvent(new CustomEvent(eventName, {
                //         //     'detail': {
                //         //         id: selectedData.name,
                //         //         imgSrc: selectedData.picture,
                //         //         x: this.label.x + this.xDivOffset,
                //         //         y: this.label.y + this.yDivOffset,
                //         //     }
                //         // }));
            }

        }

        // Dispatch Message when a point is clicked
        onMouseMove(event) {
            let f = this.updateHitTest(event);

            if (this.panel == null) {
                this.panel = new VcadMarkupPanel(this.viewer, {
                    id: 'vcadMarkupPanel',
                    title: 'Details',
                    data: this.sampleData
                });
            }

            this.panel.setVisible(false);

            if (f == true) {

                let data = this.dataDict[this._indexDbId[this.hovered]];
                this.panel.setData(data);
                this.panel.setVisible(f);
                this.update_DivLabel(event);
            }

        }


        onClick() {
            this.updateHitTest(event);
            if (!this.hovered) return;
            this.selected = this.hovered;
            console.log("Selected marker: ", this.selected);
            this.update_Line();
            this.update_DivLabel('onMarkupClick');
            this.viewer.impl.invalidate(true);
            this.viewer.clearSelection();
        }


        onCameraChanged() {
            if (this._renderer) {
                this.render();
                console.log("render")
            }
        }

        init() {
            this._width = this.viewer.canvas.width;
            this._height = this.viewer.canvas.height;

            // this._canvas = document.createElement('canvas');
            // this._canvas.className = 'debugPointCloudCanvas';
            // this._canvas.id = 'debugCanvas1';
            // this._canvas.width = this._width;
            // this._canvas.height = this._height;

            // this._canvas.style["z-index"] = 9999;
            // this._canvas.style["position"] = "absolute";
            // this._canvas.style["top"] = 0;
            // document.body.appendChild(this._canvas);

            this._sceneIDRef = new THREE.Scene();
            this._sceneIDRef.add(this.viewer.getCamera());

            this._rtTexture = this.createTextureRenderTarget(this._width, this._height)
            this._buffer = this.createTextureFromData(this._width, this._height);

            this.initRenderer()
        }

        initRenderer() {
            if (!this._renderer) {

                this._renderer = new THREE.WebGLRenderer({
                    antialias: false,
                    alpha: true
                });
                this._renderer.setPixelRatio(window.devicePixelRatio);
                this._renderer.autoClear = false;

            }
        }

        resize() {
            const s = this.viewer.getDimensions();
            const width = Math.round(s.width);
            const height = Math.round(s.height);
            if (width !== this._width || height !== this._height) {
                this._width = width;
                this._height = height;
                if (this._rtTexture) {
                    this._rtTexture.dispose();
                }
                this._rtTexture = this.createTextureRenderTarget(width, height);
                if (this._buffer) {
                    this._buffer.dispose();
                }
                this._buffer = this.createTextureFromData(width, height);
                this.render();
            }
        }



        createTextureFromData(width, height, unsigned) {
            const data = unsigned ? new Uint8Array(width * height * 4) : new Float32Array(new Float32Array(width * height * 4));
            const texture = new THREE.DataTexture(data, width, height, THREE.RGBAFormat, unsigned ? THREE.UnsignedByteType : THREE.FloatType, null, THREE.ClampToEdgeWrapping, THREE.ClampToEdgeWrapping, THREE.NearestFilter, THREE.NearestFilter);
            texture.needsUpdate = true;
            return texture;
        }

        createTextureRenderTarget(width, height) {
            return new THREE.WebGLRenderTarget(width, height, {
                wrapS: THREE.ClampToEdgeWrapping,
                wrapT: THREE.ClampToEdgeWrapping,
                minFilter: THREE.NearestFilter,
                magFilter: THREE.NearestFilter,
                format: THREE.RGBAFormat,
                type: THREE.FloatType,
                stencilBuffer: false
            });
        }


        render() {
            this.renderToBuffer(this._width, this._height, this._sceneIDRef, this.viewer.getCamera(), this._buffer, this._rtTexture, this._canvas);
        }

        renderToBuffer(width, height, scene, camera, buffer, target, canvas) {
            this._renderer.setSize(width, height);
            this._renderer.render(scene, camera, target, true);
            const gl = this._renderer.getContext();
            gl.readPixels(0, 0, width, height, gl.RGBA, gl.FLOAT, buffer.image.data);
            buffer.needsUpdate = true;
            this._renderer.render(scene, camera, undefined, true);
            if (canvas) {
                return this.renderToCanvas(scene, camera, canvas);
            }
            return null;
        }

        renderToCanvas(scene, camera, canvas, extraScene, target) {
            const width = canvas.width;
            const height = canvas.height;
            const context = canvas.getContext('2d');
            context.clearRect(0, 0, width, height);
            this._renderer.setSize(width, height);
            this._renderer.render(scene, camera, target, true);
            if (extraScene) {
                this._renderer.render(extraScene, camera);
            }
            context.drawImage(this._renderer.domElement, 0, 0, width, height);
            return context;
        }
        getID(x, y) {
            const v = this.searchBufferPixel(this._buffer, x, y, 3);
            return [Math.round(v[0]), Math.round(v[1]), Math.round(v[2]), Math.round(v[3])];
        }

        searchBufferPixel(buffer, x, y, rad) {
            const pixInfoO = this.getBufferPixel(buffer, x, y);
            if (pixInfoO[3] > 0) {
                return pixInfoO;
            }
            const minX = Math.max(x - rad, 0);
            const maxX = Math.min(x + rad, this._width - 1);
            const minY = Math.max(y - rad, 0);
            const maxY = Math.min(y + rad, this._height - 1);
            for (let yi = minY; yi <= maxY; yi++) {
                for (let xi = minX; xi <= maxX; xi++) {
                    const pixInfo = this.getBufferPixel(buffer, xi, yi);
                    if (pixInfo[3] > 0) {
                        return pixInfo;
                    }
                }
            }
            return [0, 0, 0, 0];
        }

        getBufferPixel(buffer, x, y) {
            const img = buffer.image;
            const data = img.data;
            const i = 4 * (Math.floor(x) + img.width * Math.floor(img.height - y));
            const r = data[i];
            const g = data[i + 1];
            const b = data[i + 2];
            const a = data[i + 3];



            return [r, g, b, a];
        }




    }

}