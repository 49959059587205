<template>
  <v-container fluid>
    <template v-for="(rs, rsIndex) in ruleSets">
      <v-card
        outlined
        class="pa-5 mx-6 my-5"
        rounded
        dense
        :key="`card-rule-set-${rsIndex}`"
        >
        <v-card-title class="d-flex justify-space-between">
          <div class="d-flex align-center">
            <v-text-field
              v-model="rs.ruleSetName"
              label="Rule Set Name"
              outlined
              @change="updateValue(ruleSets)"
              dense
              hide-details
              class="ma-1"
            ></v-text-field>
            <v-select
              v-model="rs.targetDataset"
              label="Target Dataset"
              class="mx-1"
              :items="availabelDatasets"
              @change="updateValue(ruleSets)"
              outlined
              hide-details
              dense
            ></v-select>
          </div>
          <div class="d-flex align-center justify-end">
            
            <v-btn
              outlined
              small
              color="error darken-1"
              :disabled="ruleSets.length == 1"
              class="vcad-btn"
              @click="deleteRuleSet(rsIndex)"
            >
              <v-icon>mdi-trash-can-outline</v-icon>
              <span>DELETE SET</span>
            </v-btn>
            <v-btn
              outlined
              small
              color="primary"
              class="vcad-btn"
              @click="addRuleToSet(rsIndex)"
            >
              <v-icon>mdi-plus</v-icon>
              <span>ADD RULE</span>
            </v-btn>
          </div>

          <!--<v-checkbox
            v-model="rs.excludeEntireDataset"
            label="Exclude Dataset"
            class="mx-1 flex-grow-1"
            @click="updateValue(ruleSets)"
            dense
          ></v-checkbox>-->
        </v-card-title>
        <v-card-text class="pt-3" style="overflow-y: auto; max-height: calc(100vh - 170px)">
          <template v-for="(r, rIndex) in rs.rules">
            <v-row :key="`rule-${rsIndex}-${rIndex}`" dense>
              <!-- FIELD -->
              <v-col :cols="10" class="d-flex align-center">
                <v-select
                  v-model="r.sourceDataset"
                  label="Source Dataset"
                  style="width: 100px"
                  class="mx-1"
                  :items="sourceDatasets"
                  @change="updateValue(ruleSets)"
                  outlined
                  hide-details
                  dense
                ></v-select>

                <!-- FIELD -->

                <v-select
                  v-model="r.field"
                  label="Field"
                  class="mx-1"
                  item-text="description"
                  style="width: 100px"
                  item-value="name"
                  :items="fields[r.sourceDataset]"
                  @change="updateValue(ruleSets)"
                  outlined
                  hide-details
                  dense
                ></v-select>

                <!-- OPERATION -->

                <v-select
                  v-model="r.operation"
                  label="Operation"
                  class="mx-1"
                  :items="availabelOperations"
                  @change="updateValue(ruleSets)"
                  style="width: 100px"
                  item-value="key"
                  item-text="text"
                  outlined
                  hide-details
                  dense
                ></v-select>

                <!-- VALUE -->

                <v-text-field
                  v-model="r.value"
                  label="Value"
                  class="mx-1"
                  @change="updateValue(ruleSets)"
                  style="width: 100px"
                  outlined
                  hide-details
                  dense
                ></v-text-field>

                <!-- CASE SENSITIVE -->

                <v-checkbox
                  hide-details
                  v-model="r.isCaseSensitive"
                  label="Is Case Sensitive"
                  style="width: 100px; margin-top: 0 !important"
                  class="pl-2"
                  @change="updateValue(ruleSets)"
                ></v-checkbox>
              </v-col>

              <v-col :cols="2" class="d-flex align-center justify-end">
                <!-- DELETE RULE -->
                <v-btn
                  outlined
                  small
                  color="error darken-1"
                  class="vcad-btn mr-1"
                  @click="deleteRule(rsIndex, rIndex)"
                >
                  <v-icon>mdi-close-box-outline</v-icon>
                  <span>Delete rule</span>
                </v-btn>
              </v-col>
            </v-row>
            <div
              :key="`AND-${rsIndex}-${rIndex}`"
              v-if="rIndex < rs.rules.length - 1"
              class="d-flex align-center"
            >
              <span>AND</span>
              <v-divider class="ml-2"></v-divider>
            </div>
          </template>
        </v-card-text>
      </v-card>
      <div
        :key="`OR-${rsIndex}`"
        v-if="rsIndex < ruleSets.length - 1"
        class="d-flex align-center"
      >
        <span>OR</span>
        <v-divider class="ml-2"></v-divider>
      </div>
      <div class="d-flex justify-end pa-3" :key="`OR-${rsIndex}`" v-else>
        <v-btn
          outlined
          small
          color="primary"
          class="vcad-btn mr-1"
          @click="addSet()"
          ><v-icon>mdi-plus</v-icon>ADD rule set</v-btn
        >
      </div>
    </template>
  </v-container>
</template>

<script>
export default {
  props: {
    ruleSets: {
      type: Array,
      required: true,
    },
    value: {
      type: Array,
      required: false,
    },
    configFilter: {
      type: [Object, Array],
      required: true,
    },
    operators: {
      type: [Object, Array],
      required: true,
    },
  },
  data: () => ({
    dialog: false,
    sourceDatasets: ["VCAD_Properties", "VCAD_Asset"],
  }),
  computed: {
    applyUserId() {
      return this.ruleSets ? this.ruleSets[0].applyUserId : null;
    },
    availabelDatasets() {
      let datasetList = [];
      this.configFilter.forEach((filter) => {
        datasetList.push(filter.name);
      });
      return datasetList;
    },
    fields() {
      let fieldDict = {};
      this.configFilter.forEach((filter) => {
        fieldDict[filter.name] = filter.fields;
      });

      return fieldDict;
    },
    availabelOperations() {
      return this.operators;
    },
  },
  methods: {
    /**
     * Add new ruleset.
     */
    addSet() {
      this.ruleSets = [
        ...this.ruleSets,
        {
          ruleSetName: "",
          applyUserId: this.applyUserId,
          rules: [{}],
          targetDataset: null,
        },
      ];
      this.updateValue(this.ruleSets);
    },
    /** Delete ruleset.
     * @param {Number} setIndex - Index of the set to manage.
     */
    deleteRuleSet(setIndex) {
      this.ruleSets.splice(setIndex, 1);
      this.updateValue(this.ruleSets);
    },
    /** Add new blank rule to a ruleset.
     * @param {Number} setIndex - Index of the set to which to add a new blank rule.
     */
    addRuleToSet(setIndex) {
      const ruleSet = this.ruleSets[setIndex];
      ruleSet.rules = [...ruleSet.rules, {}];
      this.updateValue(this.ruleSets);
    },
    /** Delete rule from ruleset.
     * @param {Number} setIndex - Index of the set to manage.
     * @param {Number} ruleIndex - Index of the rule to delete.
     */
    deleteRule(setIndex, ruleIndex) {
      const ruleSet = this.ruleSets[setIndex];
      ruleSet.rules.splice(ruleIndex, 1);
      this.updateValue(this.ruleSets);
    },

    updateValue() {
      this.$emit("input", this.ruleSets);
    },
  },
};
</script>

<style lang="scss" scoped>
</style>